import React from "react";
import Faq from "../../../../components/Faq/faq";
import Banner from "../../../../components/Bannersection/banner";
import { BsBrush, BsBracesAsterisk, BsRocketTakeoff } from "react-icons/bs";
import { Helmet } from "react-helmet";


function Facebook() {
  const pagename = [
    {
      pagetitle: "Facebook Social Boost",
      subtitle: "Facebook Social Boost",
    },
  ];
  const faqitem = [
    {
      "Faq_id": "collapseone",
      "data_bs_target": "#collapseone",
      "question": "What services do you offer?",
      "answer": "We offer a variety of services to enhance your Facebook presence, including increasing likes, views, followers, and comments on your Facebook posts and pages."
    },
    {
      "Faq_id": "collapsetwo",
      "data_bs_target": "#collapsetwo",
      "question": "How long does it take to see results after purchasing a service?",
      "answer": "You can start seeing results within 24 to 48 hours after your order has been processed. The full impact might take a bit longer depending on the size of the order and the current engagement level of your page."
    },
    {
      "Faq_id": "collapseThree",
      "data_bs_target": "#collapseThree",
      "question": "Can I customize my order?",
      "answer": "Yes, we offer customizable packages where you can choose a specific number of likes, views, followers, or comments to suit your needs."
    },
    {
      "Faq_id": "collapsefour",
      "data_bs_target": "#collapsefour",
      "question": "What happens if I don’t receive my order?",
      "answer": "If there are any issues with your order, please contact our customer support team immediately. We offer a money-back guarantee if we are unable to deliver the services as promised."
    },
    {
      "Faq_id": "collapsefive",
      "data_bs_target": "#collapsefive",
      "question": "What information do I need to provide to receive services?",
      "answer": "We only require the URL of the Facebook post or page where you want the likes, views, followers, or comments directed. We do not require your Facebook password or any personal account details."
    },
    // {
    //   "Faq_id": "collapsesix",
    //   "data_bs_target": "#collapsesix",
    //   "question": "What are the pricing options for DGi WORX Facebook social marketing services?",
    //   "answer": "Our pricing for Facebook social marketing services is tailored to the specific needs and goals of each client. We provide custom quotes based on factors such as the scope of the project, desired outcomes, and additional services required. Our aim is to offer competitive pricing while ensuring the delivery of high-quality and effective solutions."
    // }
  ];


  return (
    <div>
      <Helmet>
        <title>
          Facebook Services | Professional Website Design and Coding |
          DGi WORX
        </title>
        <meta
          name="description"
          content="Explore DGi WORX's comprehensive web development services. We specialize in professional website design and coding, creating responsive and dynamic web solutions for businesses."
        />
        <meta
          name="keywords"
          content="DGi WORX, dgiworx, web development, website design, web coding, professional web development, responsive websites, dynamic web solutions, website developers, front-end development, back-end development, DGi WORX web development"
        />
        <meta
          property="og:title"
          content="Web Development Services | Professional Website Design and Coding | DGi WORX"
        />
        <meta
          property="og:description"
          content="Explore DGi WORX's comprehensive web development services. We specialize in professional website design and coding, creating responsive and dynamic web solutions for businesses."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta property="og:url" content="https://dgiworx.ca/webdevelopment" />

        {/* Add more meta tags as needed */}
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mt-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/socialmediamarketing/facebook/logo.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/web_development/02.jpg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-10 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">
                  Enhance Your Facebook Presence
                  </span>
                  <h2 className="tp-section-title">Facebook social boost</h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      In order to boost engagement, raise brand awareness, and eventually increase sales for our clients, DGi WORX is in the vanguard of utilizing Facebook's enormous user base and potent advertising capabilities. We create incredibly successful social media campaigns that are customized to meet the specific goals of each client thanks to our in-depth knowledge of Facebook's algorithms and audience targeting features.

                      <br />
                      <br />
                      Join together with DGi WORX to take use of Facebook marketing's full potential and take your company to new heights online.

                    </p>
                  </div>
                </div>

                <div className="tp-about-two-list">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Leveraging Organic Reach
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Guaranteed Real Engagement
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Quality Content
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Safe Quick Results
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Proven Outcomes in Campaigns
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Privacy and Safety Assured
                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/project">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </section>

      {/* <!-- about-area-end --> */}

      <section className="py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">
                  Facebook Experiences Beyond Expectation
                </span>
                <h2 className="tp-section-title">
                  Our Facebook Services
                </h2>
                <p>
                  Transforming Your Facebook Insights into Engaging, Dynamic Responses

                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="sub-services  mb-80">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 ">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/facebook/followers.jpg"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12  ">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">



                        <h4 className="">Grow Your Facebook Followers</h4>
                        <p>
                          We're your trusted partner for expanding your Facebook following, committed to paving the way for your social media success. Our expert team is on a mission to attract and engage followers genuinely interested in your brand. Your Facebook presence should be as unique as your business. We excel in crafting customized strategies that align perfectly with your brand identity and objectives, whether you're a small business, a nonprofit organization, or an influencer.
                        </p>
                        <p>
                          Let us bridge the gap between your Facebook goals and reality. Whether you're starting from scratch or looking to boost your existing following, DGi WORX has the knowledge and creativity to turn your digital dreams into a thriving community of followers on Facebook.
                        </p>


                        {/* <div className="tp-btn"><a href="/contact">Chat with us</a></div> */}

                      </div>




                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sub-services mb-80">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/facebook/likes.jpg"
                        loading="lazy" sizes="100%"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">Boost Your Facebook Likes</h4>
                        <p>
                          At DGi WORX, we understand the importance of enhancing engagement on your Facebook page. Our services are meticulously tailored to meet the distinct requirements of each client. We specialize in crafting strategies that effectively increase the number of likes on your Facebook posts and page. In today's digital landscape, Facebook likes play a crucial role in establishing social proof and credibility. Our tailored approach ensures that your content resonates with your target audience, driving more likes and interactions. From compelling visuals to engaging captions, we help you create content that captures the attention of Facebook users.
                        </p>
                        <p>
                          Partner with DGi WORX to boost your Facebook likes and enhance your online presence. Our expertise and strategies are designed to elevate your brand, increase engagement, and drive success on the world's largest social media platform.
                        </p>


                        {/* <div className="tp-btn"><a href="/contact">Chat with us</a></div> */}


                      </div>


                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sub-services mb-80">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/facebook/comments.jpg" sizes="100%"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">Increase Your Facebook Comments</h4>
                        <p>
                          At DGi WORX, we specialize in maximizing engagement on your Facebook posts. Our services are dedicated to amplifying the visibility and interaction of your content on the platform. With our tailored strategies, we aim to increase the number of comments on your Facebook posts, fostering meaningful discussions and interactions.
                          <br />
                          <br />

                          Our approach to boosting Facebook comments not only increases visibility but also encourages active participation from your audience. By leveraging effective targeting and engaging content, we ensure that your posts receive relevant comments from your target audience. Partner with us to enhance your Facebook presence and foster meaningful interactions with your audience.
                        </p>
                        {/* <div className="tp-btn"><a href="/contact">Chat with us</a></div> */}

                      </div>


                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sub-services mb-10">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/facebook/reach.png"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">

                        <h4 className="">Expanding Your Facebook Reach</h4>
                        <p>
                          DGi WORX understands the importance of maximizing your Facebook audience and strives to create lasting impressions with our website development services tailored for social media. Our team meticulously analyzes your business needs, creating custom, user-friendly websites that authentically represent your brand. Our websites prioritize fast loading times and compatibility across devices and browsers, ensuring a seamless user experience for your Facebook followers.
                          <br />
                          <br />
                          Our team of experienced designers and developers utilizes their skills to craft visually appealing and highly functional websites optimized for Facebook. We recognize the uniqueness of each business and take the time to comprehend your objectives and requirements, ensuring that the design we produce is tailored to your brand. With our web design services, we guarantee that your online presence accurately reflects your brand identity and extends your reach on Facebook.
                        </p>
                        {/* <div className="tp-btn"><a href="/contact" >Chat with us</a></div> */}

                      </div>


                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* /* ***************** */}

      <section className="feature-area py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center mb-4">
              <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">
                Elevating Your Facebook Marketing
              </h2>
              <p className="">
                Discover the roadmap to success with a glimpse into 'Our Process Flow'. Dive into the structured approach that propels DGi WORX toward delivering outstanding results in Facebook marketing.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 text-center mb-4">
              <div className="social-carde">
              <h4 className="tp-section-sub-title" style={{ color: "black", fontWeight: "bold" }} >
                Before
              </h4>
              <img src="/assets/img/socialmediamarketing/facebook/before-1.png" style={{ width: "100%", display: "block", margin: "0 auto" }} alt="Before" />
              </div>
            </div>
            <div className="col-lg-6 text-center mb-4">
              <div  className="social-carde">
              <h4 className="tp-section-sub-title" style={{ color: "black", fontWeight: "bold" }}>
                After
              </h4>
              <img src="/assets/img/socialmediamarketing/facebook/after-1.png" style={{ width: "100%", display: "block", margin: "0 auto" }} alt="After" />
              </div>
              
            </div>
          </div>
        </div>
      </section>


















      {/* faq section */}

      <Faq items={faqitem} />
      {/* <SocialForm/> */}
      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div>
  );
}

export default Facebook;
