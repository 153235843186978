import React from 'react';
import Faq from '../../../../components/Faq/faq';
import Componcontact from '../../../../components/Contact/contact-compon';
import Banner from '../../../../components/Bannersection/banner';


function Ecommerce() {

  const pagename = [
    {
      pagetitle: "Ecommerce",
      subtitle: "Ecommerce",
    }
  ]
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: " How do I choose the right e-commerce platform for my business?",
      answer: "Selecting the right e-commerce platform depends on your business needs, budget, and technical expertise. Popular options include WooCommerce, Shopify, Magento, and BigCommerce. Consider factors like scalability, customization, and ease of use."
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How can I ensure the security of my e-commerce website?",
      answer: "To ensure security, use SSL certificates for data encryption, choose reputable payment gateways, regularly update your website's software and plugins, and implement strong password policies. Regular security audits and monitoring are also essential."
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "How can I attract and retain customers on my e-commerce website?",
      answer: "Attract customers through digital marketing, SEO, and social media promotion. To retain them, offer excellent customer service, personalized recommendations, loyalty programs, and hassle-free returns and exchanges."
    },
    {
      Faq_id: "collapsefour",
      data_bs_target: "#collapsefour",
      question: "How can I optimize my e-commerce website for search engines (SEO)?",
      answer: "Optimize product descriptions, use relevant keywords, create unique meta tags and titles, improve website speed, and build high-quality backlinks. Regularly update content and images to keep your website fresh."
    },
    {
      Faq_id: "collapsefive",
      data_bs_target: "#collapsefive",
      question: "Will you support me after the eCommerce website developed?",
      answer: "DGi WORX has always been dedicated to supporting customers at all levels of eCommerce web design. All the executives working in our company have excellent experience in creating eCommerce websites. DGi WORX enhances on-time delivery and we can bet you will get a bug free website on time."
    },
    {
      Faq_id: "collapsesix",
      data_bs_target: "#collapsesix",
      question: "How much does it cost to build an eCommerce website?",
      answer: "Since the creation of an eCommerce website has many features, the costs of creating an eCommerce Website will vary from one to another. The size of a website and customer preferences determine its cost. Please contact us for detailed information about this."
    },
    {
      Faq_id: "collapseseven",
      data_bs_target: "#collapseseven",
      question: "How can I optimize my e-commerce website for mobile devices?",
      answer: "Optimize your website for mobile by using responsive design, ensuring fast loading times, providing a mobile-friendly checkout process, and testing the user experience on various mobile devices."
    },
  ];


  return (
    <div>
      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}


      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-about-two-wrap mt-10 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className='tp-about-circle'>
                    <div className='tp-about-circle-two'>
                      <img src="assets/img/service/ecommerce/01.jpg" alt="" loading='lazy' />
                    </div>
                  </div>

                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img src="assets/img/service/ecommerce/02.jpg" alt="" loading='lazy' />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-two-wrapper mb-10 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">Digital Retail Solutions</span>
                  <h2 className="tp-section-title">ECOMMERCE</h2>
                  <div className="tp-section-title-wrapper">
                    <p>Discover a world of possibilities with our carefully crafted e-commerce solutions. Our e-commerce websites combine a rich array of features with the ultimate in
                      flexibility, making online shopping a breeze. Our design philosophy centers on simplicity, cost-efficiency, and user-friendliness, ensuring a seamless experience
                      for your customers.<br /><br />Our e-commerce website themes are not just elegant; they're highly customizable too, tailored to your brand's unique identity.
                      Whether you need to extend your store's reach or add new features, we're here to provide holistic solutions for your e-commerce store.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>User-Friendly Design</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Mobile Responsiveness</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Secure Payment Processing</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Product Catalog and Search</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Inventory Management</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Scalability and Performance</li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
          </div>
        </div>
      </section>




      {/* service section */}

      <section className="feature-area tp-feature-5-wrap py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Explore Our E-commerce Offerings</span>
                <h2 className="tp-section-title">Our Ecommerce Services</h2>
                <p>From creating stunning online stores to optimizing performance and user experience, our e-commerce services cover the full spectrum. Explore our offerings to find
                  tailored solutions for your online business.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className='sub-services mb-50'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/ecommerce/b2b.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">BUSINESS-TO-BUSINESS</h4>
                          <p>In today's digital age, B2B interactions often thrive in the virtual realm. Businesses rely on digital channels for researching, evaluating, and procuring
                            products and services from other businesses. As a result, maintaining a robust online presence, complete with an informative website, becomes imperative for
                            B2B entities.<br /><br />Effective communication and relationship-building are equally critical in fostering successful B2B transactions. Building strong
                            connections between businesses is the cornerstone of mutually beneficial partnerships.<br /><br />Whether you're a manufacturer, distributor, or service
                            provider, we understand the intricacies of your industry. We're here to help you leverage the power of digital technology and effective communication to
                            faster growth, build lasting relationships, and drive success in the realm of B2B commerce.</p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub-services mb-50 '>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/ecommerce/b2c.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">BUSINESS-TO-CONSUMER</h4>
                          <p>Business-to-consumer (B2C) commerce centers around businesses selling products or services directly to individual consumers. The heart of B2C transactions
                            lies in understanding and responding to consumer behavior, preferences, and demands.<br /><br />In today's digital era, the e-commerce landscape is thriving,
                            making it imperative for businesses to create websites that not only captivate visually but are also user-friendly and optimized to draw consumer traffic.
                            <br /><br />B2C transactions are all about delivering exceptional customer experiences. This requires a deep comprehension of the target audience—their needs,
                            desires, and preferences. By doing so, businesses can offer personalized experiences that resonate with consumers, fostering lasting loyalty. Additionally,
                            maintaining a competitive edge in the market entails delivering products and services that consistently meet customer expectations in terms of quality and
                            value.</p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub-services mb-10'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/ecommerce/c2c.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-10">
                          <h4 className="">CONSUMER-TO-CONSUMER</h4>
                          <p>Consumer-to-consumer (C2C) e-commerce revolves around the direct exchange of goods and services among individual consumers. In C2C transactions,
                            individuals have the opportunity to sell their products or services to fellow individuals through online platforms like eBay, Craigslist, or Facebook
                            Marketplace.<br /><br />These platforms serve as secure and efficient hubs where consumers can engage in transactions. They often generate revenue through
                            listing fees, item promotions, or payment processing services. The C2C model has witnessed remarkable growth in recent years, driven by a surge in consumers
                            flocking to online marketplaces for everything from pre-loved apparel to handcrafted creations.<br /><br />The ascent of C2C e-commerce has also empowered
                            individuals to embark on their entrepreneurial journeys. With the reach of online marketplaces, anyone can establish a small business, connect with a
                            broader audience, and expand their customer base.</p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}


      {/* contact section */}

      {/* <Componcontact/> */}

      {/* contact end section */}
    </div>
  )
}

export default Ecommerce;