import React from 'react';
import { BsBook, BsFillPersonFill, BsPcDisplayHorizontal, BsCashCoin, BsPersonWorkspace, BsShieldCheck, BsCurrencyRupee } from "react-icons/bs";
import { FaComputer, FaAward, FaRegAddressCard, FaUsers, FaUserGraduate } from "react-icons/fa6";
import { useState } from 'react';
import { CiViewList } from "react-icons/ci";
import { Helmet } from "react-helmet"
import Cardcarousel from '../../components/Carousel/Cardcarousel';
import Banner from '../../components/Bannersection/banner';
import Studentreview from '../../components/Studentreview/studentreview';



function Dgiginite() {

  const pagename = [
    {
      pagetitle: "DGignite",
      subtitle: "DGignite",
    }
  ]
  // gallary itames
  const items = [
    {
      title: 'Front Office Structure',
      imageUrl: 'assets/img/office_setup/front_office_structure.jpg',
    },
    {
      title: 'Online Training Session',
      imageUrl: 'assets/img/office_setup/online_training_session.jpg',
    },
    {
      title: 'Classroom Ambience',
      imageUrl: 'assets/img/office_setup/classroom_ambience.jpg',
    },
    {
      title: 'Recreation Area',
      imageUrl: 'assets/img/office_setup/recreation_area.jpg',
    },
    {
      title: 'Course Completion Certificate',
      imageUrl: 'assets/img/office_setup/course_completion_certificate.jpg',
    },

    // Add more items as needed
  ];


  // course section
  const course = [
    // Web Development Courses
    {
      title: 'HTML & CSS',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-1.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Javascript',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-2.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Node JS ',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-3.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'React JS',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-4.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Angular JS',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-5.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'MERN Stack',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-6.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'MEAN Stack ',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-7.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },

    {
      title: 'Full Stack',
      category: 'Web',
      imageUrl: 'assets/img/dgignite/courses/course-8.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },

    // Software Testing Courses
    {
      title: 'Manual Testing',
      category: 'Software',
      imageUrl: 'assets/img/dgignite/courses/course-9.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Automation Testing',
      category: 'Software',
      imageUrl: 'assets/img/dgignite/courses/course-10.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    // Cloud Computing Courses
    {
      title: 'Microsoft Azure',
      category: 'Cloud',
      imageUrl: 'assets/img/dgignite/courses/course-11.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'AWS',
      category: 'Cloud',
      imageUrl: 'assets/img/dgignite/courses/course-12.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    // Database Courses
    {
      title: 'MySQL',
      category: 'Database',
      imageUrl: 'assets/img/dgignite/courses/course-13.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Mongo DB',
      category: 'Database',
      imageUrl: 'assets/img/dgignite/courses/course-14.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    // Programming Courses
    {
      title: 'PHP',
      category: 'Programming',
      imageUrl: 'assets/img/dgignite/courses/course-15.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'C & C++ ',
      category: 'Programming',
      imageUrl: 'assets/img/dgignite/courses/course-16.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'C# ',
      category: 'Programming',
      imageUrl: 'assets/img/dgignite/courses/course-17.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Java',
      category: 'Programming',
      imageUrl: 'assets/img/dgignite/courses/course-18.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Python',
      category: 'Programming',
      imageUrl: 'assets/img/dgignite/courses/course-19.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Microsoft .NET',
      category: 'Programming',
      imageUrl: 'assets/img/dgignite/courses/course-20.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    // Data Analytics Courses
    {
      title: 'Power BI',
      category: 'Data Analytics',
      imageUrl: 'assets/img/dgignite/courses/course-21.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Tableau',
      category: 'Data Analytics',
      imageUrl: 'assets/img/dgignite/courses/course-22.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Alteryx',
      category: 'Data Analytics',
      imageUrl: 'assets/img/dgignite/courses/course-23.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },

    // Other Training Courses
    {
      title: 'Digital Marketing',
      category: 'Other',
      imageUrl: 'assets/img/dgignite/courses/course-24.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },
    {
      title: 'Canva',
      category: 'Other',
      imageUrl: 'assets/img/dgignite/courses/course-25.jpg',
      describe: 'The Text Content',
      price: '4000',
      oldprice: '3000'
    },

    // Add more items as needed
  ];

  const [selectedCategory, setSelectedCategory] = useState('All');

  // Function to filter projects based on the selected category
  const filteredcourse =
    selectedCategory === 'All'
      ? course
      : course.filter((course) => course.category === selectedCategory);

      const company = {
        name: "DGignite",
        url: "https://dgiworx.ca/dgiginite",
        logo: "https://dgiworx.ca/assets/img/logo/dgilogo/dgi.png",
        contactType: "customer support",
        telephone: "+61 0436291143",
        areaServed: "CA",
        sameAs: [
          "https://www.facebook.com/dgiworx",
          "https://www.instagram.com/dgiworx/",
          "https://www.behance.net/dgiworx",
          "https://www.linkedin.com/company/67087770/",
        ],
      };
    
      // Services offered by the company
      const websiteservices = [
        {
          name: "Web Development",
          type: "Web development services",
          description:
            "DGi WORX delivers unwavering website development services, guaranteeing the utmost quality and reliability.",
        },
        {
          name: "App Development",
          type: "Mobile development services",
          description:
            "Our mobile app development excels in meeting tight deadlines with customized solutions and advanced technology.",
        },
        {
          name: "Cloud Services",
          type: "Cloud services",
          description:
            "DGi WORX provides advanced cloud services for efficient data management, letting you focus on your core business.",
        },
        {
          name: "NDIS",
          type: "NDIS services",
          description:
            "We specialize in NDIS web development, creating secure and user-friendly websites for plan management.",
        },
        {
          name: "Automation",
          type: "Automation services",
          description:
            "DGi WORX offers cutting-edge automation for streamlined processes, efficiency, and growth.",
        },
        {
          name: "Migration Services",
          type: "Migration Services",
          description:
            "DGi WORX provide seamless web hosting migration with a customer-centric focus for business success.",
        },
        {
          name: "Data Analytics",
          type: "Data Analytics services",
          description:
            "Our reporting dashboards provide insights for informed resource optimization, fueling growth.",
        },
        {
          name: "Ecommerce",
          type: "Ecommerce services",
          description:
            "DGi WORX tailors tech services for e-commerce, boosting sales, conversions, and fostering growth.",
        },
        {
          name: "Design Works",
          type: "Design Works services",
          description:
            "Experience professional-grade designs from DGi WORX's graphic experts, expertly tailored to elevate your brand.",
        },
        {
          name: "SEO",
          type: "SEO services",
          description:
            "DGi WORX, we understand the pivotal role that search engine optimization (SEO) plays in the success of your online presence.",
        },
      ];
    
      const schemaOrgJSONLD = {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "Organization",
            name: company.name,
            url: company.url,
            logo: company.logo,
            contactPoint: {
              "@type": "ContactPoint",
              contactType: company.contactType,
              telephone: company.telephone,
              areaServed: company.areaServed,
            },
            sameAs: company.sameAs,
          },
          ...websiteservices.map((service) => ({
            "@type": "Service",
            name: service.name,
            description: service.description,
            provider: {
              "@type": "Organization",
              name: company.name,
            },
          })),
        ],
      };
    


  return (
    <div>
      <Helmet>
        <title>DGignite | DGi WORX</title>
        <meta
          name="description"
          content="Explore DGi WORX's DGiginite page for courses and training in canada. Elevate your skills with our innovative programs covering cloud technologies, programming, and more. Join us for industry-relevant education in Sydney, Melbourne, Brisbane, and across canada."
        />
        <meta
          name="keywords"
          content="DGiginite, DGi WORX, courses, training, canada, 
          cloud technologies, programming courses, technology education, DGi WORX programs, 
          skill development, professional training, Sydney, Melbourne, Brisbane, canada technology courses,
          IT training canada"
        />
        <meta name="geo.country" content="canada" />
        <meta name="geo.placename" content="Piara Waters, Western canada" />
        <meta property="og:locale" content="en_au" />
        <meta property="og:title" content="DGignite | DGi WORX" />
        <meta
          property="og:description"
          content="Explore DGi WORX's DGiGinite page for courses and training in Auscanada tralia. Elevate your skills with our innovative programs covering cloud technologies, programming, and more. Join us for industry-relevant education in Sydney, Melbourne, Brisbane, and across canada."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta
          property="og:url"
          content="https://www.dgiworx.ca/dgiginite"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        {/* Add more meta tags as needed */}
      </Helmet>

      {/* <!-- breadcrumb-area-start --> */}

      <Banner items={pagename} />

      {/* <!-- breadcrumb-area-end --> */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-about-two-wrap mb-60 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className='tp-about-circle'>
                    <div className='tp-about-circle-two'>
                      <img src="assets/img/dgignite/dgignite_1.jpg" alt="" loading='lazy' />
                    </div>
                  </div>

                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img src="assets/img/dgignite/dgignite_2.jpg" alt="" loading='lazy' />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-two-wrapper mb-60 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">Empowering Growth and Learning</span>
                  <h2 className="tp-section-title">TRAINING</h2>
                  <div className="tp-section-title-wrapper">
                    <p>At DGignite, we're dedicated to shaping the digital leaders of tomorrow. In a rapidly evolving tech landscape, our training programs cover a wide spectrum of
                      skills, from web and app development to programming and graphics design. Our expert instructors provide hands-on experience with industry-standard tools and
                      technologies, ensuring our students are well-prepared for real-world challenges.<br /><br />With a commitment to quality education and a track record of successful
                      job placements, DGignite is your launchpad to a thriving career in the digital world. Join us today and unlock your potential in web design, app development,
                      programming, graphics design, and more.
                    </p>
                    {/* <p>In today's digital age, having a well-designed website is crucial for businesses to establish their online presence and reach their target audience. 
                                              At DGi WORX, we offer comprehensive web design training that equips our students with the necessary skills to create visually appealing and user-friendly websites using
                                               technologies like HTML, CSS, JavaScript, and PHP. 
                                               Our experienced trainers have a deep understanding of the industry and provide hands-on training that prepares our students for real-world scenarios.
                                                We pride ourselves on being one of the leading software training institutes in Chennai,
                                                 and our commitment to providing quality education and job placements is reflected in our high success rates. 
                                              With DGi WORX, you can jumpstart your career in web design and become a sought-after professional in the industry.
                                            </p> */}
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Digital Training</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Experienced Instructors</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Cutting-Edge Technologies</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Career-Focused</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Creative Exploration</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Digital Leadership</li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
          </div>
        </div>
      </section>
      {/* <!-- about-area-end --> */}

      {/* <!-- step-area-start --> */}
      {/* <!-- solve-area-start --> */}
      <section className="solve-area tp-solve-overlay py-50">
        <div className="tp-solve-bg" data-background="assets/img/bg/solve-bg-1.jpg"></div>
        <div className="container pb-30">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-md-12 mx-auto">
              <div className="tp-section tp-section-white text-center mb-100">
                <span className="tp-section-sub-title">Empowering Learning</span>
                <h2 className="tp-section-title ">Training Methodology</h2>
                <p className='section-sub-title '>Our training methodology blends expert-led instruction with hands-on projects, fostering practical skills and preparing you for real-world success in the digital domain.</p>
              </div>
            </div>
          </div>
          <div className="row coursegrid">
            <div className="">
              <a href="#" className="tp-solve-item mb-60 wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                <div className="tp-solve-icon">
                  <i><BsBook /></i>
                </div>
                <div className="tp-solve-content">
                  <h5 className="tp-solve-title"><br />THEORY</h5>
                </div>
                <div className="tp-solve-shape">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
                <div className="tp-solve-shape-top">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
              </a>
            </div>
            <div className="addition">
              <a href="#" className="tp-solve-item mb-60 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s" >
                <div className="tp-solve-icon">
                  <i><FaComputer /></i>
                </div>
                <div className="tp-solve-content">
                  <h5 className="tp-solve-title"><br />PRACTICALS</h5>
                </div>
                <div className="tp-solve-shape">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
                <div className="tp-solve-shape-top">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
              </a>
            </div>
            <div className=" addition">
              <a href="#" className="tp-solve-item mb-60 wow bounceIn" data-wow-duration=".4s" data-wow-delay=".4s">
                <div className="tp-solve-icon">
                  <i><CiViewList /></i>
                </div>
                <div className="tp-solve-content">
                  <h5 className="tp-solve-title"><br />ASSIGNMENT</h5>
                </div>
                <div className="tp-solve-shape">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
                <div className="tp-solve-shape-top">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
              </a>
            </div>
            <div className=" addition">
              <a href="#" className="tp-solve-item mb-60 wow bounceIn" data-wow-duration=".4s" data-wow-delay=".4s">
                <div className="tp-solve-icon">
                  <i><FaAward /></i>
                </div>
                <div className="tp-solve-content">
                  <h5 className="tp-solve-title"><br />CERTIFICATIONS</h5>
                </div>
                <div className="tp-solve-shape">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
                <div className="tp-solve-shape-top">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
              </a>
            </div>
            <div className="addition">
              <a href="#" className="tp-solve-item mb-60 wow bounceIn" data-wow-duration=".6s" data-wow-delay=".5s">
                <div className="tp-solve-icon">
                  <i><FaRegAddressCard /></i>
                </div>
                <div className="tp-solve-content">
                  <h5 className="tp-solve-title">RESUME<br />PREPARATION</h5>
                </div>
                <div className="tp-solve-shape">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
                <div className="tp-solve-shape-top">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
              </a>
            </div>
            <div className="addition">
              <a href="#" className="tp-solve-item mb-60 wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                <div className="tp-solve-icon">
                  <i><FaUsers /></i>
                </div>
                <div className="tp-solve-content">
                  <h5 className="tp-solve-title">ATTEND<br />INTERVIEW</h5>
                </div>
                <div className="tp-solve-shape">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
                <div className="tp-solve-shape-top">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
              </a>
            </div>
            <div className="additionline">
              <a href="#" className="tp-solve-item mb-60 wow bounceIn " data-wow-duration=".6s" data-wow-delay=".6s">
                <div className="tp-solve-icon">
                  <i><FaUserGraduate /></i>
                </div>
                <div className="tp-solve-content">
                  <h5 className="tp-solve-title">YOU GOT <br />JOB</h5>
                </div>
                <div className="tp-solve-shape">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
                <div className="tp-solve-shape-top">
                  <img src="assets/img/shape/cardborder.png" alt="" loading="lazy" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- solve-area-end --> */}
      {/* <!-- step-area-end --> */}


      {/* <!-- feature-area-start --> */}
      <section className="feature-area  py-50 p-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="tp-section tp-section-white text-center mb-100">
                <span className="tp-section-sub-title">Unleash Your Potential</span>
                <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">Our Speciality</h2>
                <h3 className='section-sub-title col-lg-10 col-sm-12 text-dark mx-auto'>Transforming enthusiasts into skilled professionals through hands-on, industry-focused training. At DGignite, we take pride in nurturing talent and empowering individuals to thrive in the ever-evolving digital landscape.</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 dgiginitespeciality">
              <div className="tp-feature-item mb-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-feature-icon">
                  <i><BsFillPersonFill className="flaticon-solution-1" /></i>
                </div>
                <div className="tp-feature-content">
                  <h4 className="tp-feature-content-title">Guided by Industry Experts</h4>
                  <p>Our mentors are seasoned professionals who bring their problem-solving expertise, creativity, and resourcefulness to help you reach your objectives effectively.</p>
                </div>
                <div className="tp-feature-shape-two">
                  <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 dgiginitespeciality">
              <div className="tp-feature-item mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-feature-icon">
                  <i><BsPersonWorkspace className="flaticon-solution-1" /></i>
                </div>
                <div className="tp-feature-content">
                  <h4 className="tp-feature-content-title">Practical Learning</h4>
                  <p>We immerse our learners in hands-on experiences, enabling them to tackle real-world challenges, experiment, and develop problem-solving skills essential for thriving in their chosen domains.</p>
                </div>
                <div className="tp-feature-shape-two">
                  <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 dgiginitespeciality">
              <div className="tp-feature-item mb-30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-feature-icon">
                  <i><BsPcDisplayHorizontal className="flaticon-solution-1" /></i>
                </div>
                <div className="tp-feature-content">
                  <h4 className="tp-feature-content-title">Build Live Projects</h4>
                  <p>Our commitment to excellence shines through as we focus on delivering exceptional user experiences, paying meticulous attention while building live projects that exceed expectations.</p>
                </div>
                <div className="tp-feature-shape-two">
                  <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 dgiginitespeciality">
              <div className="tp-feature-item mb-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-feature-icon">
                  <i>< BsCashCoin className="flaticon-solution-1" /></i>
                </div>
                <div className="tp-feature-content">
                  <h4 className="tp-feature-content-title">Accessible Training Costs</h4>
                  <p>At DGi WORX, we believe access to high-quality training shouldn't come at a premium. That's why we offer accessible and affordable fees without compromising program excellence.</p>
                </div>
                <div className="tp-feature-shape-two">
                  <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 dgiginitespeciality">
              <div className="tp-feature-item mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-feature-icon">
                  <i><BsBook className="flaticon-solution-1" /></i>
                </div>
                <div className="tp-feature-content">
                  <h4 className="tp-feature-content-title">Interview Preparation</h4>
                  <p>Our courses prioritize real-world scenarios and include interview-style questions, equipping learners with the skills and confidence needed for career success.</p>
                </div>
                <div className="tp-feature-shape-two">
                  <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 dgiginitespeciality">
              <div className="tp-feature-item mb-30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-feature-icon">
                  <i><BsShieldCheck className="flaticon-solution-1" /></i>
                </div>
                <div className="tp-feature-content">
                  <h4 className="tp-feature-content-title">Job Assistance</h4>
                  <p>Our commitment to learners extends to their journey. Our job assistance program includes counseling, resume, and interview coaching, empowering candidates for success in the job market.</p>
                </div>
                <div className="tp-feature-shape-two">
                  <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-feature-shape">
          <div className="tp-feature-shape-one w-img">
            <img src="assets/img/feature/one/features-bg.png" alt="" loading="lazy" />
          </div>
        </div>
      </section>
      {/* <!-- feature-area-end --> */}

      {/* <!-- dgi---course--type --> */}
      <section className="feature-area tp-feature-3-bg py-50" data-background="assets/img/feature/three/feature-3-bg-1.jpg" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dgi-course-type-grid">
                <div>
                  <div className="dgi-course-type text-center wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                    <div className="tp-feature-3-item-icon dgi-course-type-image">
                      <img src="assets/img/dgignite/dgigniteclass/classroom.jpg" alt="" loading="lazy" />
                    </div>
                    <div className='dgi-course-item-Title'>
                      <h4 className="tp-feature-3-item-title">Classroom Training</h4>
                      <button className="tp-btn">
                        <a href='/contact'>Enquire Now</a>
                      </button>
                    </div>
                    <div className="dgi-course-item-content">
                      <ul>
                        <li>Our Classroom Training option offers a traditional learning experience where students gather in a physical classroom setting. It provides a collaborative
                          environment for face-to-face interaction with instructors and peers, allowing for immediate feedback and hands-on learning.</li>
                      </ul>
                      <div>

                      </div>
                    </div>
                  </div>
                </div>




                <div className="">
                  <div className="dgi-course-type text-center  wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                    <div className="tp-feature-3-item-icon">
                      {/* <i><BsPersonVideo className="flaticon-consultant" /></i> */}
                      <img src="assets/img/dgignite/dgigniteclass/online.jpg" alt="" loading="lazy" />
                    </div>
                    <div className='dgi-course-item-Title'>
                      <h4 className="tp-feature-3-item-title">Online Training</h4>
                      <button className="tp-btn">
                        <a href='/contact'>Enquire Now</a>
                      </button>
                    </div>
                    <div className="dgi-course-item-content">

                      {/* <!-- <span>B</span> --> */}
                      <ul className='px-3 text-left'>
                        <li>Online Training brings the classroom to your fingertips, offering flexible and convenient learning from anywhere with an internet connection. Our online
                          courses provide access to expert instructors, interactive materials, and a supportive online community.</li>
                      </ul>
                      <div>

                      </div>
                    </div>
                  </div>
                </div>



                <div className="">
                  <div className="dgi-course-type text-center  wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                    <div className="tp-feature-3-item-icon">
                      {/* <i><BsPersonVideo className="flaticon-consultant" /></i> */}
                      <img src="assets/img/dgignite/dgigniteclass/corproate.jpg" alt="" loading="lazy" />
                    </div>
                    <div className='dgi-course-item-Title'>
                      <h4 className="tp-feature-3-item-title">Corporate Training</h4>
                      <button className="tp-btn">
                        <a href='/contact'>Enquire Now</a>
                      </button>
                    </div>
                    <div className="dgi-course-item-content">
                      {/* <!-- <span>B</span> --> */}
                      <ul className='px-3 text-left'>
                        <li>Our Corporate Training programs are tailored to meet the specific needs of organizations looking to upskill their workforce. We collaborate closely with
                          businesses to design customized training solutions that align with their industry, objectives, and team expertise. </li>
                      </ul>
                      <div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* <!-- feature-area-end --> */}
      </section>

      <section className='course py-50'>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 mb-50">
              <div className="tp-section tp-section-white text-center ">
                <span className="tp-section-sub-title">Explore Our Programs</span>
                <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">Courses We Offer</h2>
                <h3 className='section-sub-title col-lg-10 col-sm-12 text-dark mx-auto fs-6'>Discover a world of opportunities with our diverse range of courses. Our courses are
                  designed to empower you with the knowledge and expertise needed to excel in the digital age.</h3>
              </div>
              <div className="category-filter">
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('All')}>All</button>
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('Software')}>Testing</button>
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('Cloud')}>Cloud</button>
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('Database')}>Database </button>
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('Web')}>Web </button>
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('Programming')}>Programming</button>
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('Data Analytics')}>Data Analytics</button>
                <button className="tp-btn mt-10" onClick={() => setSelectedCategory('Other')}>Other  </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="dgi-gallery-course tp-feature-3-wrapper">
                {filteredcourse.map((course, index) => (

                  <div className="" >

                    <div className="dgi-course-item text-center wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s" key={index}>
                      <div className="dgi-course-img">
                        <img src={course.imageUrl} alt={course.title} loading='lazy' />
                      </div>
                      <div className="dgi-course-content">
                        <h4 className=""><a href="#">{course.title}</a></h4>
                        {/* <!-- <span>G</span> --> */}



                        <div className='coursenone'>
                          {/* <p className='coursenone'>{course.describe}</p> */}
                          {/* <p className='dgicourseprice'><b><BsCurrencyRupee />{course.price}</b><del><BsCurrencyRupee />{course.oldprice}</del></p> */}
                          <div className='coursenone'>
                            <p className='text-left'>Training Modes:Online | Classroom  </p>
                            <button className="tp-btn Dgi-course-button">
                              <a href="/contact">
                                Enquire Now</a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                ))}
              </div>
            </div>
          </div>
        </div>

      </section>



      {/* <!-- project-area-start --> */}

      {/* <!-- project-area-start --> */}
      <section className="project-area tp-project-4 fix py-50">
        <div className="tp-project-4-bg" data-background="assets/img/project/five/project-5-bg-1.jpg" style={{ backgroundImage: `url(assets/img/project/five/project-5-bg-1.jpg)` }}></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <div className="tp-section tp-section-white text-center mb-50">
                <span className="tp-section-sub-title">Office Snapshot</span>
                <h2 className="tp-section-title  text-dark ">Step Inside Our Office</h2>
                <p className=' '>Explore images of our workspace, where creativity and innovation come to life. Take a virtual tour of our inspiring environment.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="office">
          <div className="container">
            <Cardcarousel items={items} />
          </div>
        </div>
      </section>


      <section className="project-area tp-project-4 fix  py-50  ">
        <div className="tp-project-4-bg" data-background="assets/img/project/five/project-5-bg-1.jpg" style={{ backgroundImage: `url(assets/img/project/five/project-5-bg-1.jpg)` }}></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <div className="tp-section tp-section-white text-center mb-50">
                <span className="tp-section-sub-title">Student Insights</span>
                <h2 className="tp-section-title  text-dark ">Glowing Student Testimonials</h2>
                <p className=' '>Discover what our students have to say about their transformative experiences at DGignite. Read firsthand accounts of their journeys, insights, and
                  successes, and gain a glimpse into the impact our courses have on their careers and aspirations.</p>
              </div>
            </div>
          </div>
          <div className="office">
            <div className="container">
              <Studentreview />
            </div>
          </div>
        </div>
      </section>




      {/* <Componcontact /> */}
    </div>
  )
}

export default Dgiginite;