import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
// import { useTypewriter, Cursor } from 'react-simple-typewriter';
// import { useState, useEffect } from 'react';
import './home.css';
import { TypeAnimation } from 'react-type-animation';
import { AiOutlineArrowRight } from 'react-icons/ai'
import { BsBrush, BsSearch, BsEmojiLaughing, BsBracesAsterisk, BsCodeSlash, BsPhone, BsDatabase, BsClouds, BsCart4, BsPcDisplayHorizontal, BsPersonWorkspace } from "react-icons/bs";
import project from './project.json';
import Testimonial from '../../components/Clienttestimonial/testimonial';
// import Preloader from '../../components/Preloder/preloder';
import Clientlogo from '../../components/Clientlogo/clientlogo.json';
import Clientlogo2 from '../../components/Clientlogo/clientlogo2.json';
import Boxcard from '../../components/Cardesection/boxcard';
import Clientlogos from '../../components/Clientlogo/clientlogos';
import Rightlogo from '../../components/Clientlogo/rightlogo';
// import Animction from './animction';
import Particlesanimation from '../../components/Particlesanimation/particlesanimation';
import BanneremailIN from '../../components/BanneremailInput/banneremailIN';
import BanneremailAU from '../../components/BanneremailInput/BanneremailAu';
import BanneremailUS from '../../components/BanneremailInput/banneremailUS';
// import Particlesanimation from '../../components/Particlesanimation/particlesanimation';




function Home() {
    const [userCountry, setUserCountry] = useState(null);
    const apiToken = process.env.REACT_APP_API_TOKEN;

    useEffect(() => {
        axios
          .get(`https://ipinfo.io?token=${apiToken}`)
          .then((response) => {
            const data = response.data;
            const detectedCountry = data.country;
            setUserCountry(detectedCountry);
          })
          .catch((error) => {
            console.error('Error fetching user IP information', error);
          });
      }, []);
    
      // Determine the header component based on the user's country
      let BannerEmail;
      if (userCountry === 'IN') {
        BannerEmail = <BanneremailIN />;
      } else if (userCountry === 'US') {
        BannerEmail = <BanneremailUS />;
      } else if (userCountry === 'AU') {
        BannerEmail = <BanneremailAU />;
      } else {
        // Default header for other countries
        BannerEmail = <BanneremailIN/>;
      }

      // Conditionally render social media links
//   const socialMediaLinks = userCountry === 'US' ? (
    <div className="tp-banner-social d-none d-lg-block" style={{ left: '0%' }}>
      <a href="https://www.instagram.com/dgiworx/" target="_blank">INSTAGRAM</a>
      {/* <a href="https://www.instagram.com/dgiworx/" target="_blank">INSTAGRAM</a>
      <a href="https://www.instagram.com/dgiworx/" target="_blank">INSTAGRAM</a> */}
    </div>
//   ) : (
//     <div className="tp-banner-social d-none d-lg-block">
//       <a href="https://www.instagram.com/dgiworx/" target="_blank">INSTAGRAM</a>
//       <a href="https://www.behance.net/dgiworx" target="_blank">BEHANCE</a>
//       <a href="https://www.facebook.com/dgiworx" target="_blank">FACEBOOK</a>
//     </div>
//   );
    // const [isLoading, setLoading] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 3000);
    // }, []);

    // service section
    const service = [
        {
            icons: BsCodeSlash,
            title: "Web Development",
            description: "DGi WORX delivers unwavering website development services, guaranteeing the utmost quality and reliability."
        },
        {
            icons: BsPhone,
            title: "App Development",
            description: "Our mobile app development excels in meeting tight deadlines with customized solutions and advanced technology."
        },
        {
            icons: BsClouds,
            title: "Cloud Services",
            description: "DGi WORX provides advanced cloud services for efficient data management, letting you focus on your core business."
        },

        {
            icons: BsPcDisplayHorizontal,
            title: "Automation",
            description: "DGi WORX offers cutting-edge automation for streamlined processes, efficiency, and growth."
        },
        {
            icons: BsDatabase,
            title: "Migration Services",
            description: "DGi WORX provide seamless web hosting migration with a customer-centric focus for business success."
        },
        {
            icons: BsSearch,
            title: "Data Analytics",
            description: "Our reporting dashboards provide insights for informed resource optimization, fueling growth."
        },
        {
            icons: BsCart4,
            title: "Ecommerce",
            description: "DGi WORX tailors tech services for e-commerce, boosting sales, conversions, and fostering growth."
        },
        {
            icons: BsBrush,
            title: "Design Works",
            description: "Experience professional-grade designs from DGi WORX's graphic experts, expertly tailored to elevate your brand."
        },
        {
            icons: BsPersonWorkspace,
            title: "Training",
            description: "DGi WORX enhance your professional skills through collaborative learning with peers and expert instructors."
        },
    ]    
    const company = {
        name: "DGi WORX",
        url: "https://dgiworx.ca/",
        logo: "https://dgiworx.ca/assets/img/logo/dgilogo/dgi.png",
        contactType: "customer support",
        telephone: "+61 0436291143",
        areaServed: "CA",
        sameAs: [
          "https://www.facebook.com/dgiworx",
          "https://www.instagram.com/dgiworx/",
          "https://www.behance.net/dgiworx",
          "https://www.linkedin.com/company/67087770/"
        ]
      };
    
      // Services offered by the company
      const websiteservices = [
        {
          name: "Web Development",
          type: "Web development services",
          description: "DGi WORX delivers unwavering website development services, guaranteeing the utmost quality and reliability."
        },
        {
          name: "App Development",
          type: "Mobile development services",
          description: "Our mobile app development excels in meeting tight deadlines with customized solutions and advanced technology."
        },
        {
          name: "Cloud Services",
          type: "Cloud services",
          description: "DGi WORX provides advanced cloud services for efficient data management, letting you focus on your core business."
        },
        {
            name: "NDIS",
            type: "NDIS services",
            description: "We specialize in NDIS web development, creating secure and user-friendly websites for plan management."
        },
        {
            name: "Automation",
            type: "Automation services",
            description: "DGi WORX offers cutting-edge automation for streamlined processes, efficiency, and growth."
        },
        {
            name: "Migration Services",
            type: "Migration Services",
            description: "DGi WORX provide seamless web hosting migration with a customer-centric focus for business success."
        },
        {
            name: "Data Analytics",
            type: "Data Analytics services",
            description: "Our reporting dashboards provide insights for informed resource optimization, fueling growth."
        },
        {
            name: "Ecommerce",
            type: "Ecommerce services",
            description: "DGi WORX tailors tech services for e-commerce, boosting sales, conversions, and fostering growth."
        },
        {
            name: "Design Works",
            type: "Design Works services",
            description: "Experience professional-grade designs from DGi WORX's graphic experts, expertly tailored to elevate your brand."
        },
        {
            name: "SEO",
            type: "SEO services",
            description: "DGi WORX, we understand the pivotal role that search engine optimization (SEO) plays in the success of your online presence."
        }
      ];
    
      const schemaOrgJSONLD = {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "Organization",
            "name": company.name,
            "url": company.url,
            "logo": company.logo,
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": company.contactType,
              "telephone": company.telephone,
              "areaServed": company.areaServed
            },
            "sameAs": company.sameAs
          },
          ...websiteservices.map(service => ({
            "@type": "Service",
            "name": service.name,
            "description": service.description,
            "provider": {
              "@type": "Organization",
              "name": company.name
            }
          }))
        ]
      };
    return (
        <div>
            {/* {isLoading ? (
                <Preloader />
            ) : ( */}
            <Helmet>

                <title>DGi WORX | Software Development Company</title>
                <meta name="description" content="DGi WORX is a leading 
                IT company providing innovative solutions in software development, and IT consulting." />
                <meta name="keywords" content="app development android, IT services canada,
                companies for web development, DGi WORX, Cloud Services,
                Migration Services, ecommerce platforms, IoT, Dgiworx canada, 
                best software development company, custom website, ecommerce website, 
                crm software, software maintainance, digital marketing company, technology training, 
                mobile app development, android app development, iOS app development" />
                <meta name="geo.country" content="canada" />
                <meta name="geo.placename" content="Piara Waters, Western canada" />
                <meta property="og:locale" content="en_au" />
                <meta property="og:title" content="DGi WORX | Software Development Company" />
                <meta property="og:description" content="Elevate your brand with DGi WORX, providing cutting-edge digital solutions for business growth." />
                <meta property="og:image" content="/assets/img/dgiworx-home-embedded.jpg" />
                <meta property="og:url" rel='canonical' content="https://dgiworx.ca" />
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrgJSONLD)}
                </script>
            </Helmet>
            <>
                {/* <!-- header-cart-end --> */}
                <main>

                    {/* <!-- baner-area-start --> */}
                    <section className="banner-area tp-banner-bg theme-bg home-banner-bg p-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tp-banner">
                                    
                                        <span className="tp-banner-sub-title wow fadeInUp" data-wow-duration="0.5s" data-wow-delay=".5s">Solutions for your businesses </span>
                                        <h1 className="tp-banner-title wow fadeInUp" data-wow-duration="0.5s" data-wow-delay=".7s">
                                            <b>World</b> Class<br />
                                            <span>
                                                <TypeAnimation
                                                    sequence={[
                                                        // Same substring at the start will only be typed out once, initially
                                                        'IT Services',
                                                        2000, // wait 1s before replacing "Mice" with "Hamsters"
                                                        'Web Development',
                                                        2000,
                                                        'App Development',
                                                        2000,
                                                        'Logo Design',
                                                        2000
                                                    ]}
                                                    wrapper="span"
                                                    typeSpeed={50}
                                                    backSpeed={100}
                                                    repeat={Infinity}
                                                />
                                            </span>
                                        </h1>
                                        <br/><br/>

                                          {/* {BannerEmail} */}
                                       
                                    </div>
                                    <Particlesanimation/>
                                </div>
                            </div>
                            {/* <Particlesanimation/> */}
                            
                        </div>
                        {/* <div className="tp-banner-social d-none d-lg-block">
                            <a href="https://www.instagram.com/dgiworx/" target="_blank">INSTAGRAM</a>
                            <a href="https://www.behance.net/dgiworx" target="_blank">BEHANCE</a>
                            <a href="https://www.facebook.com/dgiworx" target="_blank">FACEBOOK</a>
                        </div> */}
                         {/* {socialMediaLinks} */}
                        <div className="tp-banner-social d-none d-lg-block" style={{ left: '0%' }}>
                            <a href="https://www.facebook.com/dgiworx" target="_blank">FACEBOOK</a>
                            <a href="https://www.instagram.com/dgiworx/" target="_blank">INSTAGRAM</a>
                            <a href="https://www.behance.net/dgiworx" target="_blank">BEHANCE</a>
                        </div>
                        <div className="tp-banner-shape">
                            {/* <div className="tp-banner-shape-one">
                                <img src="../assets/img/banner/one/banner-one-shape-1.png" alt="" loading="lazy" />
                            </div> */}
                            {/* <div className="tp-banner-shape-two">
                                <img src="../assets/img/banner/one/banner-one-shape-2.png" alt="" loading="lazy" />
                            </div> */}
                        </div>
                        <div className="tp-banner-scroll-down">
                            <div className="tp-banner-scroll-shape">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="238" height="70.594" viewbox="0 0 238 70.594">
                                        <defs>
                                            <style>
                                                {`.cls-1 {
                                            fill: #fff;
                                            fill-rule: evenodd;
                                        }`}
                                            </style>
                                        </defs>
                                        <path id="scrolldown-shape-1.svg" className="cls-1" d="M847,980s40.865-5.656,69-43,81.412-36.188,103,0,66,43,66,43H847Z" transform="translate(-847 -909.406)"></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="tp-banner-scroll-mouse">
                            <a href="#about">
                                <span>
                                    <svg id="scroll-down.svg" xmlns="http://www.w3.org/2000/svg" width="16" height="26" viewbox="0 0 16 26">
                                        <defs>
                                            <style>
                                                {`.cls-5,
                                            .cls-6 {
                                                fill: none;
                                            stroke: #131023;
                                            stroke-width: 1px;
                                             }

                                            .cls-6 {
                                                fill - rule: evenodd;
                                            }`}
                                            </style>
                                        </defs>
                                        <circle className="cls-5" cx="8.5" cy="7.5" r="2"></circle>
                                        <path className="cls-6" d="M963,922h5a5,5,0,0,1,5,5v15a5,5,0,0,1-5,5h-5a5,5,0,0,1-5-5V927A5,5,0,0,1,963,922Z" transform="translate(-957.5 -921.5)"></path>
                                    </svg>
                                </span>
                            </a>
                            <p>Scroll Down</p>
                        </div>
                        {/* <div className="tp-banner-video d-none d-md-block">
                            <a className="popup-video" href="#"><i><BsFillPlayFill className="flaticon-play" /></i></a>
                        </div> */}
                        <div class="tp-banner-bg-shape" data-background="../assets/img/banner/one/banner-thumb-1.jpg" style={{ backgroundImage: `url(assets/img/banner/homebanner.jpg)` }}></div>
                    </section>
                    {/* <!-- baner-area-end --> */}

                    {/* <!-- about-area-start --> */}
                    <section id="about" className="about-area tp-about-two py-100">
                        <div className="container pt-20">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="tp-about-two-wrap  wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                        <div className="tp-about-two-thumb">
                                            <div className='tp-about-circle'>
                                                <div className='tp-about-circle-two'>
                                                     <img src="assets/img/home/about-1.jpg" alt="" loading='lazy' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                                            <div className="tp-thumb-common-overlay wow"></div>
                                            <img src="assets/img/home/about-2.png" alt="" loading='lazy' />
                                        </div>
                                        <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                                            <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="tp-about-two-wrapper mb-60 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                                        <div className="tp-section mb-40">
                                            <span className="tp-section-sub-title">Explore DGi WORX: Unveiling Our Company</span>
                                            <h2 className="tp-section-title">Elevate Your Business with <br /> Our Effective Solutions</h2>
                                            <div className="tp-section-title-wrapper">
                                                <p>At DGi WORX, we're dedicated to innovation in web design and software development, serving businesses of all sizes. From startups to
                                                    corporate giants, we provide cost-effective, high-quality solutions for thriving in the digital world. Our expert team thrives on
                                                    challenges, using proven digital marketing strategies and custom solutions to keep you ahead of competitors.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tp-about-two-list">
                                            <ul>
                                                <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Industry Pioneers</li>
                                                <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Exceeding Expectations</li>
                                                <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Specialized Expertise</li>
                                                <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Innovation at Our Core</li>
                                                <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Client-Centric Approach</li>
                                                <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Proven Track Record</li>
                                            </ul>
                                        </div>
                                        <div className="tp-about-details d-flex align-items-center flex-wrap">
                                            <div className="tp-about-btn mr-30">
                                                <a className="tp-btn" href="/about">Discover More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tp-about-two-shape">
                            <div className="tp-about-two-shape-4">
                                <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
                            </div>
                        </div>

                    </section>
                    {/* <!-- about-area-end --> */}



                    {/* <!-- step-area-start --> */}
                    <section className="step-area step-area-back py-50">
                        <div className="container">
                            <div className="tp-section text-center mt-10 mb-50">
                                {/* <span className="tp-section-sub-title">Recently completed work</span> */}
                                <h4 className="tp-section-title">Operational Symphony</h4>
                                <p className='text-center'>we pride ourselves on our meticulously crafted workflow that ensures efficiency, transparency, and top-notch results.</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tp-step-wrapper d-flex justify-content-between">
                                        <div className="tp-step-item text-center mb-30 wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                                            <div className="tp-step-item-count">
                                                <span>1</span>
                                            </div>
                                            <div className="tp-step-item-overlay p-relative mb-30">
                                                <div className="tp-step-item-thumb">
                                                    <img src="assets/img/thumbs/step/step-thumb-1.png" alt="" loading="lazy" />
                                                </div>
                                                <div className="tp-step-item-thumb-icon">
                                                    <i><BsSearch className="flaticon-design" /></i>
                                                </div>
                                            </div>
                                            <div className="tp-step-item-content">
                                                <h4 className="tp-step-item-title">Research</h4>
                                                <p>Delving deep to understand your needs, <br />goals, and market dynamics.</p>
                                            </div>
                                        </div>
                                        <div className="tp-step-item tp-step-item-even text-center mb-30 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                                            <div className="tp-step-item-count">
                                                <span>2</span>
                                            </div>
                                            <div className="tp-step-item-overlay p-relative mb-30">
                                                <div className="tp-step-item-thumb">
                                                    <img src="assets/img/thumbs/step/step-thumb-2.png" alt="" loading="lazy" />
                                                </div>
                                                <div className="tp-step-item-thumb-icon">
                                                    <i><BsBrush className="flaticon-design" /></i>
                                                </div>
                                            </div>
                                            <div className="tp-step-item-content">
                                                <h4 className="tp-step-item-title">Design</h4>
                                                <p>Transforming ideas into visually captivating<br /> and user-centric concepts.</p>
                                            </div>
                                        </div>
                                        <div className="tp-step-item text-center mb-30 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                                            <div className="tp-step-item-count">
                                                <span>3</span>
                                            </div>
                                            <div className="tp-step-item-overlay p-relative mb-30">
                                                <div className="tp-step-item-thumb">
                                                    <img src="assets/img/thumbs/step/step-thumb-3.png" alt="" loading="lazy" />
                                                </div>
                                                <div className="tp-step-item-thumb-icon">
                                                    <i><BsBracesAsterisk className="flaticon-design" /></i>
                                                </div>
                                            </div>
                                            <div className="tp-step-item-content">
                                                <h4 className="tp-step-item-title">Development</h4>
                                                <p>Bringing designs to life through<br /> skilled coding.</p>
                                            </div>
                                        </div>
                                        <div className="tp-step-item tp-step-item-even text-center mb-30 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".6s">
                                            <div className="tp-step-item-count">
                                                <span>4</span>
                                            </div>
                                            <div className="tp-step-item-overlay p-relative mb-30">
                                                <div className="tp-step-item-thumb">
                                                    <img src="assets/img/thumbs/step/step-thumb-4.png" alt="" loading="lazy" />
                                                </div>
                                                <div className="tp-step-item-thumb-icon">
                                                    <i><BsEmojiLaughing className="flaticon-design" /></i>
                                                </div>
                                            </div>
                                            <div className="tp-step-item-content">
                                                <h4 className="tp-step-item-title">Deployment</h4>
                                                <p>Launching your project into the digital <br />realm for users to experience. </p>
                                            </div>
                                        </div>
                                        <div className="tp-step-line-shape d-none d-xl-block">
                                            <img src="assets/img/thumbs/step/step-line-shape-1.svg" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- step-area-end --> */}

                    <section className="brand-area py-50">
                        <div className="container-flu">
                            <Clientlogos item={Clientlogo} />
                            <Rightlogo item={Clientlogo2}/>
                        </div>
                    </section>

                    {/* <!-- feature-area-start --> */}
                    <section className="feature-area py-50 p-relative">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-12">
                                    <div className="tp-section text-center mb-50">
                                        <span className="tp-section-sub-title">Elevating Possibilities</span>
                                        <h2 className="tp-section-title">Explore What We Offer</h2>
                                        <p> Explore our comprehensive range of offerings designed to address your unique needs and empower your business for success.</p>
                                    </div>
                                </div>
                            </div>
                            <Boxcard item={service} />
                        </div>
                        <div className="tp-feature-shape">
                            <div className="tp-feature-shape-one w-img">
                                <img src="assets/img/feature/one/features-bg.png" alt="" loading="lazy" />
                            </div>
                        </div>
                    </section>
                    {/* <!-- feature-area-end --> */}


                    {/* <!-- project-area-start --> */}
                    <section id="project-id" className="project-area tp-project-widthpl-15 pr-15 fix py-50">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="tp-section text-center mb-50">
                                        <span className="tp-section-sub-title">Showcase of Our Recent Triumphs</span>
                                        <h4 className="tp-section-title">Check Our latest projects </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='latopview'>
                                {/* <div className="row-custom ">
                                    {
                                        project.map(project => {
                                            return (
                                                <div className="col-custom" key={project.id} >
                                                    <div className="tp-project-item p-relative">
                                                        <div className="tp-project-thumb">
                                                            <img src={project.image} alt={project.title} loading="lazy" />
                                                        </div>
                                                        <div className="tp-project-content">
                                                            <div className="tp-project-text">
                                                                <h4 className="tp-project-title mb-15"><a href="">
                                                                    {project.title}</a></h4>
                                                                <ul className="tp-project-meta">
                                                                    <li>{project.category}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="tp-project-shape">
                                                            <img src="assets/img/home_projects/project_shape.png" alt="" loading="lazy" />
                                                        </div>
                                                        <div className="tp-project-arrow">
                                                            <a href={project.url} target='_blank'>
                                                                <span>
                                                                    <img src="assets/img/project/project-arrow.png" alt="" loading="lazy" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    };
                                </div> */}
                                <ul className="project-cards">
                                    {
                                        project.map(project => {
                                            return (

                                                <li>
                                                    <a href={project.url} target='_blank' className="pro-card" key={project.id}>
                                                        <img  src={project.image} alt={project.title} className="pro_card__image"  />
                                                        <div className="pro_card__overlay">
                                                            <div className="pro_card__header">
                                                                <svg className="pro_card__arc" xmlns="http://www.w3.org/2000/svg">
                                                                    <path />
                                                                </svg>
                                                                {/* <img className="pro_card__thumb" src="https://i.imgur.com/7D7I6dI.png" alt="" /> */}
                                                                <div className="pro_card__header-text">
                                                                    <h3 className="pro_card__title">{project.title}</h3>
                                                                    <span className="pro_card__status">{project.category}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>

                                            )
                                        })
                                    }
                                </ul>

                            </div>
                            <div className='mobileview'>
                                <div className="row">

                                    {
                                        project.map(project => {
                                            return (
                                                <div className="col-lg-4 col-md-6" key={project.id}>
                                                    <div className="tp-project-3-item mb-30 tp-thumb-common fix wow fadeInRight" data-wow-duration="1.2s"
                                                        data-wow-delay=".6s">
                                                        <div className="tp-project-3-thumb">
                                                            <div className="tp-thumb-common-overlay wow"></div>
                                                            <img src={project.image} alt={project.title} loading="lazy" />
                                                        </div>
                                                        <div className="tp-project-3-info d-flex justify-content-between align-items-center">
                                                            <div className="tp-project-3-content">
                                                                <span>{project.category}</span>
                                                                <h4 className="tp-project-3-title under-line-white"><a href={project.url}>{project.title}</a></h4>
                                                            </div>
                                                            <div className="tp-project-3-icon">
                                                                <a href={project.url}><i ><AiOutlineArrowRight className="fa-regular fa-arrow-right" /></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    };
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- project-area-end --> */}


                    {/* <!-- testimonial-area-start --> */}
                    <section className="testimonial-area tp-testimonial-inner  py-50">
                        <div className="container">
                            <Testimonial />
                            <div className="row">

                            </div>
                        </div>
                    </section>
                    {/* <!-- testimonial-area-end --> */}



                    {/* <!-- services-area-start --> */}
                    <section className="services-area tp-services-plr dgi-back-area mt-30 py-100">
                        <div className="container-fluid tp-services-width">
                            <div className="row">
                                <div className="col-xl-4 col-lg-6">
                                    <div className="tp-services-item d-flex mb-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                        <div className="tp-services-item-head">
                                            <span>D</span>
                                        </div>
                                        <div className="tp-services-item-thumb">
                                            <img src="assets/img/services/one/1.jpg" alt="" loading="lazy" />
                                        </div>
                                        <div className="tp-services-item-content">
                                            <h4 className="tp-services-item-title">Digital Dynamo<br />for Your Success</h4>
                                            <p>DGi WORX is your dynamic partner in the digital realm, delivering tailored solutions for your success.</p>
                                        </div>
                                        <div className="tp-services-item-shape">
                                            <img src="assets/img/services/one/shape/shape-01.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6">
                                    <div className="tp-services-item d-flex mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                        <div className="tp-services-item-thumb">
                                            <img src="assets/img/services/one/2.jpg" alt="" loading="lazy" />
                                        </div>
                                        <div className="tp-services-item-content">
                                            <h4 className="tp-services-item-title">Gearing Up for Growth<br /></h4>
                                            <p>We provide tailored solutions to empower your digital presence and ensure success.</p>
                                        </div>
                                        <div className="tp-services-item-head">
                                            <span>G</span>
                                        </div>
                                        <div className="tp-services-item-shape">
                                            <img src="assets/img/services/one/shape/shape-01.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6">
                                    <div className="tp-services-item d-flex mb-30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                                        <div className="tp-services-item-thumb">
                                            <img src="assets/img/services/one/3.jpg" alt="" loading="lazy" />
                                        </div>
                                        <div className="tp-services-item-content">
                                            <h4 className="tp-services-item-title">Impactful Innovations</h4>
                                            <p>Turning Visions into Reality with Our Cutting-Edge Solutions and Unparalleled Expertise.</p>
                                        </div>
                                        <div className="tp-services-item-head">
                                            <span>i</span>
                                        </div>
                                        <div className="tp-services-item-shape">
                                            <img src="assets/img/services/one/shape/shape-01.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- services-area-end --> */}

                   
                   
                    {/* <!-- brand-area-start --> */}
                    {/* <section className="brand-area py-50">
                        <div className="container-flu">
                            <Clientlogos item={Clientlogo} />
                            <Rightlogo item={Clientlogo2}/>
                        </div>
                    </section> */}
                    {/* <!-- brand-area-end --> */}



                    {/* <!-- blog-area-start --> */}
                    {/* <section className="blog-area pt-150 pb-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="tp-section text-center mb-60">
                                        <span className="tp-section-sub-title">Our Latest Update</span>
                                        <h4 className="tp-section-title">Read Our Latest News</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="tp-blog-wrapper">
                                <div className="row gx-0">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="tp-blog-item text-center">
                                            <div className="tp-blog-item-thumb tp-thumb-common fix overlay-anim mb-20">
                                                <div className="tp-thumb-common-overlay wow"></div>
                                                <a href="blog-details.html.htm">
                                                    <img src="assets/img/blog/one/blog-thumb-1.jpg" alt="" loading="lazy" />
                                                </a>
                                            </div>
                                            <div className="tp-blog-item-content">
                                                <div className="tp-blog-item-tag mb-20">
                                                    <a href="blog-details.html.htm">BRAND</a>
                                                    <a href="blog-details.html.htm">SOLUTION</a>
                                                </div>
                                                <h4 className="tp-blog-item-title mb-30">
                                                    <a href="blog-details.html.htm">How to Create an <br /> Awesome Website</a>
                                                </h4>
                                                <div className="tp-blog-item-btn">
                                                    <a href="blog-details.html.htm">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="tp-blog-item text-center">
                                            <div className="tp-blog-item-thumb tp-thumb-common fix overlay-anim mb-20">
                                                <div className="tp-thumb-common-overlay wow"></div>
                                                <a href="blog-details.html.htm">
                                                    <img src="assets/img/blog/one/blog-thumb-2.jpg" alt="" loading="lazy" />
                                                </a>
                                            </div>
                                            <div className="tp-blog-item-content">
                                                <div className="tp-blog-item-tag mb-20">
                                                    <a href="blog-details.html.htm">BRAND</a>
                                                    <a href="blog-details.html.htm">SOLUTION</a>
                                                </div>
                                                <h4 className="tp-blog-item-title mb-30">
                                                    <a href="blog-details.html.htm">What is Holding Back <br /> The IT Solution</a>
                                                </h4>
                                                <div className="tp-blog-item-btn">
                                                    <a href="blog-details.html.htm">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="tp-blog-item tp-blog-item-last text-center">
                                            <div className="tp-blog-item-thumb tp-thumb-common fix overlay-anim mb-20">
                                                <div className="tp-thumb-common-overlay wow"></div>
                                                <a href="blog-details.html.htm">
                                                    <img src="assets/img/blog/one/blog-thumb-3.jpg" alt="" loading="lazy" />
                                                </a>
                                            </div>
                                            <div className="tp-blog-item-content">
                                                <div className="tp-blog-item-tag mb-20">
                                                    <a href="blog-details.html.htm">BRAND</a>
                                                    <a href="blog-details.html.htm">SOLUTION</a>
                                                </div>
                                                <h4 className="tp-blog-item-title mb-30">
                                                    <a href="blog-details.html.htm">Easy to Use Software <br /> New Ennovation</a>
                                                </h4>
                                                <div className="tp-blog-item-btn">
                                                    <a href="blog-details.html.htm">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/* <!-- blog-area-end --> */}

                    {/* <Componcontact /> */}
                </main>

            </>
            {/* )} */}
        </div>
    );
};

export default Home;