import React from 'react';
import Faq from '../../../../components/Faq/faq';
import Componcontact from '../../../../components/Contact/contact-compon';
import Banner from '../../../../components/Bannersection/banner';


function Automation() {

  const pagename = [
    {
      pagetitle: "Automation",
      subtitle: "Automation",
    }
  ]
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "Why should my business consider automation services?",
      answer: "Automation services can significantly improve operational efficiency, reduce errors, enhance productivity, and free up human resources for more strategic tasks. They are essential for staying competitive in a rapidly evolving digital landscape."
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "What types of automation services do you offer?",
      answer: "We offer a wide range of automation services, including IT automation, network automation, security automation, and cloud automation. Our solutions are tailored to meet specific business needs and objectives."
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Is automation suitable for small businesses or startups?",
      answer: "Yes, automation services are adaptable to businesses of all sizes. Small businesses and startups can benefit by automating repetitive tasks, reducing costs, and optimizing resource utilization."
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question: "Do you offer customized automation solutions tailored to specific business needs?",
      answer: "Yes, we provide customized automation solutions to address unique business requirements. We work closely with clients to design and implement solutions that align with their objectives."
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question: "How secure are automation solutions, and do they pose cybersecurity risks?",
      answer: "Automation solutions are designed with security in mind. They can actually enhance cybersecurity by reducing the risk of human error. Properly configured and monitored, automation enhances security."
    },
  ];

  return (
    <div>
      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}


      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-about-two-wrap mt-10 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className='tp-about-circle'>
                    <div className='tp-about-circle-two'>
                      <img src="assets/img/service/automation/01.jpg" alt="" loading='lazy' />
                    </div>
                  </div>

                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img src="assets/img/service/automation/02.jpg" alt="" loading='lazy' />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-two-wrapper mb-10 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">Efficiency through Automation</span>
                  <h2 className="tp-section-title">AUTOMATION</h2>
                  <div className="tp-section-title-wrapper">
                    <p>At DGi WORX, we offer cutting-edge IT automation solutions that empower businesses to achieve unprecedented efficiency and agility. Our services simplify
                      intricate processes, mitigate errors, and amplify productivity. By automating repetitive tasks, you can expedite IT infrastructure and application deployment
                      while optimizing resources.<br /><br />Our automation
                      solutions span diverse IT domains, encompassing network, security, cloud, and DevOps. Leveraging state-of-the-art tools and technologies, we craft robust
                      automation workflows capable of handling intricate scenarios and effortlessly scaling with your business's growth.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Efficiency Improvement</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Error Reduction</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Cost Savings</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Consistency</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Scalability</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Faster Deployment</li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
          </div>
        </div>
      </section>




      {/* service section */}

      <section className="feature-area tp-feature-5-wrap py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Unleashing Efficiency</span>
                <h2 className="tp-section-title">Our Automation Services</h2>
                <p>Our services are meticulously designed to
                  streamline complex processes, enhance efficiency, and eliminate errors, all while boosting productivity.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className='sub-services mb-50'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/automation/cloud_automation.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">CLOUD AUTOMATION</h4>
                          <p>In a landscape where businesses increasingly rely on virtualization and cloud computing for their IT infrastructure, the role of automation becomes
                            paramount. Automating routine and repetitive tasks not only saves precious time and enhances operational efficiency but also mitigates the risk of human
                            error, elevating the overall stability and reliability of the IT environment.<br /><br />Automation empowers businesses to swiftly and effortlessly adjust
                            the scale of their infrastructure, provision resources as needed, and respond agilely to dynamic market conditions. Through the adept utilization of tools
                            such as configuration management, orchestration, and monitoring, DGi WORX collaborates with clients to automate their IT processes and optimize operations,
                            fostering peak efficiency and agility. Join us in the realm of cloud automation, where we transform IT operations into a powerhouse of productivity,
                            resilience, and responsiveness.</p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub-services mb-50'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/automation/network_automation.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">NETWORK AUTOMATION</h4>
                          <p>DGi WORX's Network Automation solution empowers Communication Service Providers (CSPs) to construct intelligent, automated networks primed to support
                            cutting-edge technologies and services such as 5G and edge computing. With the ever-growing demand for high-speed data and ultra-low latency, CSPs face
                            the imperative of rapidly scaling their network infrastructure while preserving seamless performance and reliability.<br /><br />Our Network Automation
                            offering delivers an end-to-end solution, automating the entire network lifecycle — from design and deployment to management and optimization. This
                            empowers CSPs to redirect their focus towards innovation and elevating customer satisfaction. Through the utilization of AI-powered automation, CSPs can
                            drive cost reduction, enhance operational efficiency, and expedite the delivery of novel services and experiences to their valued customers.</p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub-services mb-10'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/automation/security_automation.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">SECURITY AUTOMATION</h4>
                          <p>In an era where cyber threats are constantly evolving in complexity and frequency, traditional security measures alone are no longer adequate to safeguard
                            organizations. Security automation, powered by AI and machine learning, has emerged as a pivotal component of a robust security strategy.
                            <br /><br />
                            DGi WORX Security Automation revolutionizes security operations, empowering organizations to swiftly identify and respond to threats, curtail response
                            times, and fortify their overall security posture. Automation enables security teams to redirect their efforts towards intricate and strategic endeavors
                            while concurrently mitigating the risk of human error.<br /><br />With DGi WORX Security Automation, organizations harness advanced technologies to actively
                            monitor, analyze, and respond to threats in real-time, reducing the likelihood of data breaches and other security incidents.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}


      {/* contact section */}

      {/* <Componcontact/> */}

      {/* contact end section */}
    </div>
  )
}

export default Automation;