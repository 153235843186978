import React from 'react';
import Faq from '../../components/Faq/faq';
import { Helmet } from "react-helmet";
import Banner from '../../components/Bannersection/banner';
import Boxcard from '../../components/Cardesection/boxcard';
import { BsCashCoin, BsMeta } from "react-icons/bs";
import { FiTarget } from "react-icons/fi";
import { HiComputerDesktop } from "react-icons/hi2";
import { SiMicrostrategy, SiCampaignmonitor } from "react-icons/si";
import { LuTestTubes } from "react-icons/lu";
import { TbReport } from "react-icons/tb";
import { AiOutlineSchedule, AiOutlineAudit, AiOutlineGoogle } from "react-icons/ai";
import { GoGoal } from "react-icons/go";
import { SlCalender } from "react-icons/sl";
import { FaUsers } from "react-icons/fa";
import { LiaAdSolid } from "react-icons/lia"



function Socialmedia() {

  const pagename = [
    {
      pagetitle: "Social Media",
      subtitle: "Social Media",
    }
  ]
  // Social Media Marketing Process
  const marketingprocess = [
    {
      icons: AiOutlineSchedule,
      title: "Engaging Posting Schedule",
      description: "Effective engagement on social media hinges on consistently delivering captivating content. Crafting and adhering to a well-planned posting schedule is a crucial element in enhancing your strategy, ensuring prolonged engagement with your audience."
    },
    {
      icons: AiOutlineAudit,
      title: "Social Media Audit",
      description: "Conducting a comprehensive social media audience analysis provides valuable insights into your target demographic's mindset. By understanding your audience better, you can tailor your strategies for success, ensuring rapid growth in engagement, likes, and followers."
    },
    {
      icons: GoGoal,
      title: "Marketing Objectives ",
      description: "Prior to implementing any plugins or tools, it's essential to establish a clear understanding of your strategy's primary goals. Setting objectives provides a roadmap for your social media marketing efforts, helping you to define your aims and develop effective strategies to achieve them."
    },
    {
      icons: BsCashCoin,
      title: "Accounts Improvisation",
      description: "An impactful social media presence is indispensable in today's digital landscape. Crafting an appealing profile or optimizing your existing social media accounts can drive increased leads and potential clients to your platform, ultimately contributing to revenue growth."
    },
    {
      icons: SlCalender,
      title: "Content Calendar",
      description: "Having a well-structured content calendar for your marketing plan and social media strategy is essential for maintaining a consistently active online presence. To engage your audience effectively, it's crucial to provide them with valuable content regularly, and a content calendar plays a pivotal role."
    },
    {
      icons: FaUsers,
      title: "Build Audience",
      description: "What's the purpose of a page if there's no audience to engage with and appreciate your content? Success in social media marketing hinges on building a dedicated audience for your page. This strategic management is essential for establishing brand value and connecting with your customers."
    }
  ]

  // Social Ads Management sectin
  const carde = [
    {
      icons: FiTarget,
      title: "Optimal Targeting",
      description: "We delve deep into your target audience, unraveling individual segments, and aligning them seamlessly with your campaigns, marketing objectives, and business goals."
    },
    {
      icons: HiComputerDesktop,
      title: "Ad Copy and Creative",
      description: "Our team specializes in crafting persuasive ad copy, captivating headlines, and visually stunning content designed to captivate your target audience and drive conversions."
    },
    {
      icons: SiMicrostrategy,
      title: "Bidding Strategies",
      description: "Strategic Budgeting and Bidding: Whether launching ads or boosting posts, our team strategically sets, monitors, and fine-tunes your campaign budgets and bids for optimal results."
    },
    {
      icons: SiCampaignmonitor,
      title: "Campaign Management",
      description: "After launching your campaigns, we maintain a vigilant watch and continually enhance performance. Our emphasis is on maximizing your ad spend by prioritizing the most effective ads."
    },
    {
      icons: LuTestTubes,
      title: "A/B Testing",
      description: "We rigorously test your ads across multiple variables, eliminating underperforming elements until we pinpoint the perfect combination of audience segment, creative & relevance."
    },
    {
      icons: TbReport,
      title: "Tracking and Reporting",
      description: "Experience 100% transparency with weekly check-ins to assess campaign performance and detailed monthly reports, keeping you well-informed about your advertising endeavors."
    }
  ]

  // faq section 
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "Why do I need social media management for my business?",
      answer: "Social media is a powerful tool for building brand awareness, driving website traffic, and generating leads. Effective social media management can help you connect with your audience, increase customer loyalty, and achieve your business goals."
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "Which social media platforms should I focus on?",
      answer: "The choice of social media platforms depends on your target audience and business niche. We can help you identify the platforms that are most relevant to your business and create a strategy tailored to your goals."
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Can you manage paid advertising campaigns on social media?",
      answer: "Yes, we offer targeted paid social advertising management services. We can create, optimize, and monitor paid ad campaigns to reach your desired audience and maximize your return on investment (ROI)."
    },

    {
      Faq_id: "collapsefour",
      data_bs_target: "#collapsefour",
      question: "What is the recommended posting frequency on social media?",
      answer: "Posting frequency varies by platform and audience. We analyze your specific needs and industry trends to determine the ideal posting schedule for your business."
    },
    {
      Faq_id: "collapsefive",
      data_bs_target: "#collapsefive",
      question: "Can I customize your social media management packages?",
      answer: "Yes, we offer flexibility in our packages to cater to your specific needs and budget. We can tailor our services to match your objectives and preferences."
    },
    {
      Faq_id: "collapsesix",
      data_bs_target: "#collapsesix",
      question: "Why should I consider paid advertising on social media?",
      answer: "Paid advertising on social media allows you to reach a larger and highly targeted audience quickly. It's an effective way to promote products or services, generate leads, and increase website traffic."
    },
    {
      Faq_id: "collapseseven",
      data_bs_target: "#collapseseven",
      question: "Which social media platforms are best for paid advertising?",
      answer: "The choice of platforms depends on your target audience and campaign goals. Common options include Google Ads, Facebook Ads, Instagram Ads, Twitter Ads, LinkedIn Ads, and more. We can help you select the most suitable platforms for your business."
    },
    {
      Faq_id: "collapseeight",
      data_bs_target: "#collapseeight",
      question: "What budget should I allocate for paid advertising on social media?",
      answer: "Your advertising budget will depend on your objectives, industry, and competition. We work with you to establish a budget that aligns with your goals and maximizes your ad spend efficiently."
    },
    {
      Faq_id: "collapsenine",
      data_bs_target: "#collapsenine",
      question: "Do you offer A/B testing for paid ads?",
      answer: "Yes, we conduct A/B testing to optimize your paid ads. We test different ad copy, visuals, targeting options, and more to find the most effective combinations that drive better results."
    },
    {
      Faq_id: "collapseten",
      data_bs_target: "#collapseten",
      question: " What sets your paid advertising services apart from others?",
      answer: "Our team combines strategic expertise with data-driven insights to deliver high-performing paid advertising campaigns. We focus on optimizing your ad spend and ensuring your ads reach the right audience at the right time for maximum impact."
    },
  ]
  const company = {
    name: "SocialMedia",
    url: "https://dgiworx.ca/socialmedia",
    logo: "https://dgiworx.ca/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+61 0436291143",
    areaServed: "CA",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: company.name,
        url: company.url,
        logo: company.logo,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: company.contactType,
          telephone: company.telephone,
          areaServed: company.areaServed,
        },
        sameAs: company.sameAs,
      },
      // ...websiteservices.map((service) => ({
      //   "@type": "Service",
      //   name: service.name,
      //   description: service.description,
      //   provider: {
      //     "@type": "Organization",
      //     name: company.name,
      //   },
      // })),
    ],
  };


  return (
    <div>
      {/* <!-- breadcrumb-area-start --> */}
      <Helmet>
        <title>Social Media | DGi WORX</title>
        <meta name="description" content="Connect with DGi WORX on social media. Explore our social media marketing and management services tailored for canada businesses. Stay updated on the latest technology trends, promotions, and engage with our community." />
        <meta name="keywords" content="auto dealer social media marketing,
        small business social media marketing company, marketing agency social media, 
        social digital agency, social media courses canada, 
        social media, social media marketing, social media management, 
        canada social media services, marketing agency social media, social media engagement, 
        technology trends, promotions, community engagement" />
        <meta name="geo.country" content="canada" />
        <meta name="geo.placename" content="Piara Waters, Western canada" />
        <meta property="og:locale" content="en_au" />
        <meta property="og:title" content="Social Media | DGi WORX" />
        <meta property="og:description" content="Connect with DGi WORX on social media. Explore our social media marketing and management services tailored for canada businesses. Stay updated on the latest technology trends, promotions, and engage with our community." />
        <meta property="og:image" content="/assets/img/dgiworx-home-embedded.jpg" />
        <meta property="og:url" content="https://dgiworx.ca/socialmedia" />
        
        <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>

      <Banner items={pagename} />

      {/* <!-- breadcrumb-area-end --> */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-about-two-wrap mb-10 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className='tp-about-circle'>
                    <div className='tp-about-circle-two'>
                      <img src="assets/img/social_media/01.jpg" alt="" loading='lazy' />
                    </div>
                  </div>

                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img src="assets/img/social_media/02.jpg" alt="" loading='lazy' />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-two-wrapper mb-10 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-section  mb-40">
                  <span className="tp-section-sub-title">Experience the Power of Digital Excellence</span>
                  <h2 className="tp-section-title">OUR MOTTO IS DIGITAL EXCELLENCE<br /></h2>
                  <div className="tp-section-title-wrapper">
                    <p>At DGi WORX, we firmly believe that digital age success hinges on achieving excellence across your online presence. Our mission is to guide you through the ever-evolving
                      landscape of social media and digital marketing with precision and expertise.<br /><br />In today's fast-paced digital world, staying current with trends and technologies can
                      be daunting. Hence, we're dedicated to leading industry advancements, ensuring your brand remains not only relevant but outstanding.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Social Media Planning</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Engaging Content Creation</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Targeted Paid Advertising</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Comprehensive Analytics</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Expert Guidance</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Customized Solutions</li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
          </div>
        </div>

      </section>
      {/* <!-- about-area-end --> */}


      {/* Acquire New Customers with Targeted Paid Social Ads Management */}
      <section className="feature-area py-50  p-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Your Roadmap to Social Media Mastery</span>
                <h2 className="tp-section-title">Mastering Social Media Marketing with DGi WORX</h2>
                <p>Chart your social media mastery journey with DGi WORX and excel in the digital world with strategic expertise and creativity at your side.</p>
              </div>
            </div>
          </div>
          <div className="tp-section text-center mb-50">
            {/* <span className="tp-section-sub-title">Recently completed work</span> */}
            <h2 className="tp-section-title"></h2>
          </div>
          <Boxcard item={marketingprocess} />

        </div>
        {/* <div className="tp-feature-shape">
          <div className="tp-feature-shape-one w-img">
            <img src="assets/img/feature/one/features-bg.png" alt="" loading="lazy" />
          </div>
        </div> */}
        <div class="tp-solution-shape-1">
          <img src="assets/img/shape/solution-shape-1.png" alt="" />
        </div>
      </section>
      {/* Acquire New Customers with Targeted Paid Social Ads Management end */}




      {/* Acquire New Customers with Targeted Paid Social Ads Management */}
      <section className="feature-area py-50 p-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Unlock Growth with Targeted Paid Social Ads </span>
                <h2 className="tp-section-title">Acquire New Customers with Targeted Paid Social Ads Management</h2>
                <p>Discover the power of precision with our expertly managed paid social advertising campaigns. Reach your ideal audience, drive engagement, and convert leads into
                  loyal customers. Let's boost your brand together!</p>
              </div>
            </div>
          </div>
          <div className="tp-section text-center mb-50">
            {/* <span className="tp-section-sub-title">Recently completed work</span> */}
            <h2 className="tp-section-title"></h2>
          </div>
          <Boxcard item={carde} />

        </div>
        <div className="tp-feature-shape">
          <div className="tp-feature-shape-one w-img">
            <img src="assets/img/feature/one/features-bg.png" alt="" loading="lazy" />
          </div>
        </div>
      </section>
      {/* Acquire New Customers with Targeted Paid Social Ads Management end */}


      {/* pricing-area section */}
      <section className="pricing-area tp-price-blue tp-price-wrap py-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-blog-3-wrapper">
                <div className="tp-section tp-section-two text-center mb-65">
                  <span className="tp-section-sub-title">A Comparative Guide</span>
                  <h2 className="tp-section-title">Choose Your Service</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="tp-price mb-40 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".6s">
                <div className="tp-price-thumb">
                  <img src="assets/img/social_media/service_comparision/01.jpg" alt="" loading="lazy" />
                </div>
                <div className="tp-price-icon mb-20">
                  <i><AiOutlineGoogle className="flaticon-airplane" /></i>
                </div>
                <div className="tp-price-badge mb-20">
                  <span>Google Ads</span>
                </div>
                {/* <div className="tp-price-heading mb-40">
                        <div className="tp-price-content">
                           <h4 className="tp-price-value">14,999<span>/monthly</span></h4>
                        </div>
                     </div> */}
                <div className="tp-price-features mb-45">
                  <ul>
                    <li>Campaigns Setup</li>
                    <li className="tp-price-inactive">Keyword Research</li>
                    <li className="tp-price-inactive">Ad Copy Creation</li>
                    <li>Conversion Tracking</li>
                    <li>Google Analytics Integration</li>
                    <li>Search Ads</li>
                    <li>Display Ads</li>
                    <li>Shopping Campaign</li>
                    <li>Remarketing</li>
                    <li>YouTube Ads</li>
                    <li>Detailed Monthly Reporting</li>
                    <li>No Fixed Term Contract</li>
                    <li>Setup Monthly Google Ads Budget</li>
                    <li>Included Channels: Google Ads</li>
                  </ul>
                </div>
                <div className="tp-btn">
                  <a href="/contact">Chat with us</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-price mb-40 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".6s">
                <div className="tp-price-thumb">
                  <img src="assets/img/social_media/service_comparision/02.jpg" alt="" loading="lazy" />
                </div>
                <div className="tp-price-icon mb-20">
                  <i><BsMeta className="flaticon-airplane" /></i>
                </div>
                <div className="tp-price-badge mb-20">
                  <span>Facebook & Instagram Ads</span>
                </div>
                {/* <div className="tp-price-heading mb-40">
                        <div className="tp-price-content">
                           <h4 className="tp-price-value">14,999<span>/monthly</span></h4>
                        </div>
                     </div> */}
                <div className="tp-price-features mb-45">
                  <ul>
                    <li>Campaigns Setup</li>
                    <li className="tp-price-inactive">Account Strategy</li>
                    <li className="tp-price-inactive">Campaign Structure</li>
                    <li>Audience Targeting</li>
                    <li>Audience Testing & Optimisation</li>
                    <li>Landing Page Analysis</li>
                    <li>Creative Design</li>
                    <li>Catalogue Setup</li>
                    <li>Conversion Pixel Installation</li>
                    <li>Remarketing Funnel Setup</li>
                    <li>Monthly Reporting</li>
                    <li>No Fixed Term Contract</li>
                    <li>Engagement Focus</li>
                    <li>Included Channels: Facebook & Instagram Ads</li>
                  </ul>
                </div>
                <div className="tp-btn">
                  <a href="/contact">Chat with us</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-price mb-40 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".6s">
                <div className="tp-price-thumb">
                  <img src="assets/img/social_media/service_comparision/03.jpg" alt="" loading="lazy" />
                </div>
                <div className="tp-price-icon mb-20">
                  <i ><LiaAdSolid className="flaticon-speed" /></i>
                </div>
                <div className="tp-price-badge mb-20">
                  <span>Google and Meta Ads</span>
                </div>
                {/* <div className="tp-price-heading mb-40">
                        <div className="tp-price-content">
                           <h4 className="tp-price-value">24,999<span>/monthly</span></h4>
                        </div>
                     </div> */}
                <div className="tp-price-features mb-45">
                  <ul>
                    <li>Campaigns Setup</li>
                    <li className="tp-price-inactive">Keyword Research & Analysis</li>
                    <li className="tp-price-inactive">Conversion Tracking</li>
                    <li>Google Analytics Integration</li>
                    <li>Search Ads</li>
                    <li>Display Ads</li>
                    <li>Shopping Campaign</li>
                    <li>Remarketing</li>
                    <li>YouTube Ads</li>
                    <li>Facebook & Instagram Ads</li>
                    <li>Monthly Reporting</li>
                    <li>No Fixed Term Contract</li>
                    <li>Unlimited Campaign</li>
                    <li>Included Channels: Google Ads and Meta Ads</li>
                  </ul>
                </div>
                <div className="tp-btn">
                  <a href="/contact">Chat with us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="tp-price-shape-1">
            <img src="assets/img/shape/line-5-shape-2.png" alt="" loading="lazy"/>
         </div> */}
      </section>

      {/* pricing-area end */}

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}


      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}


    </div>
  )
}

export default Socialmedia;