import React from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactFormCA() {
    const sendEmail = (event) => {
        event.preventDefault();
      
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_CA_SERVICE_ID, process.env.REACT_APP_EMAILJS_CA_TEMPLATE_ID, event.target, process.env.REACT_APP_EMAILJS_CA_USER_ID)
          .then((result) => {
            console.log(result.text);
            toast.success('Message sent successfully!', {
                position: toast.POSITION.BOTTOM_CENTER
            });
          }, (error) => {
            console.log(error.text);
            toast.error('Error sending message. Please try again later.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
          });
      
        // Clear the form after sending the email
        event.target.reset();
      };
    return (
        <div>
            {/* <!-- form-area-start --> */}
            <section className="form-area tp-faq-wrapper pt-160 pb-55">
                <div className="tp-faq-bg" data-background="assets/img/shape/faq-2-shape-1.jpg"></div>

                <div className="container mt-40">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="tp-section text-center mb-50">
                                <span className="tp-section-sub-title">Drop Us a Line</span>
                                <h2 className="tp-section-title">Need Help? Sure we are Online!</h2>
                                <p>Ready to take your website to the next level? Join the millions of satisfied website owners who have already partnered with us.
                                    Fill out the form below to learn more about our special offer and how we can help your business thrive online</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="tp-form-wrapper mb-60 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                {/* <div className="tp-form-content">
                                    {/* <span className='text-center'>Contact With Us</span> *
                                    <h4 className="tp-form-title text-center">Drop Us a Line</h4>
                                </div> */}
                                <div className="tp-form-wrap">
                                <form id="contactform"  action="" name="contactform" method="post" onSubmit={sendEmail}>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="first_name" id="fname" type="text" placeholder="First Name" required/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="last_name" id="lname" type="text" placeholder="Last Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="email" id="email" type="email" placeholder="Email Address" required/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="phone" id="phonenumber" type="text" placeholder="Phone Number" required/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-form-input">
                                            <textarea name="comments" id="message" placeholder="Your Comment" required></textarea>
                                        </div>
                                        <div className="tp-form-btn">
                                            <button className="tp-btn">Send Your Message</button>
                                            <ToastContainer />
                                        </div>
                                    </form>
                                </div>
                                <div className="tp-form-shape">
                                    <img src="assets/img/shape/contact-2-shape-1.png" alt="" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- form-area-end --> */}
        </div>
    )
}

export default ContactFormCA;