import React from 'react';
import Faq from '../../../../components/Faq/faq';
import Componcontact from '../../../../components/Contact/contact-compon';
import Banner from '../../../../components/Bannersection/banner';
import { TbWorldSearch, TbCurrencyDollar, TbUserCode } from 'react-icons/tb'
import { BsPcDisplayHorizontal, BsBook, BsFillPersonFill, BsSearch, BsGear, BsCart3 } from "react-icons/bs";
import Boxcard from '../../../../components/Cardesection/boxcard';


function Seo() {

  const pagename = [
    {
      pagetitle: "SEO",
      subtitle: "SEO",
    }
  ]
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "How long does it take for SEO to work?",
      answer: "Based on your specific industry and the level of SEO efforts by your competitors, you can expect to see improvements in 6-9 months, and achieving many top-ranking positions might take up to 12 months. While faster results are possible, they are not the norm."
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How many keywords do you focus on?",
      answer: "Our SEO approach stands out from other agencies. We focus intensively on your most profitable product or service to maximize traffic and lead generation for it. The number of keywords we target varies, depending on the diversity of search phrases and the search volume associated with that product or service."
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Do you guarantee SEO rankings / positions?",
      answer: "No, we do not make such promises. Instead, we offer clear reporting and documentation of our efforts, demonstrating the impact on traffic and financial returns for our clients. Caution is advised when dealing with anyone who guarantees specific results, as search rankings fluctuate daily."
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question: "Can you audit my website?",
      answer: "Absolutely! We offer one-time SEO website audits."
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question: "Can you write my SEO content (copywriting) for me?",
      answer: "Yes, indeed! We have an in-house SEO copywriter who can craft text for your website, newsletters, brochures, blog posts, social media captions, and more!"
    },
    {
      Faq_id: "collapseSix",
      data_bs_target: "#collapseSix",
      question: "What does SEO cost in 2024?",
      answer: "The cost to outperform your competitors varies based on the scope of work required, including strategy development, content creation, analysis, and website updates, among other tasks. Our typical SEO projects begin at $1650 per month, and we do not require long-term contracts."
    },
    // {
    //   Faq_id: "collapseSeven",
    //   data_bs_target: "#collapseSeven",
    //   question: "I'm not in Western Australia, can we still work together?",
    //   answer: "Yes, indeed! We collaborate with businesses worldwide. We're flexible and can conduct video calls via Zoom or Google Meet."
    // },

  ];






  const seocard = [
    {
      icons: BsSearch,
      title: "SEO Strategy ",
      description: "Understanding your business, including past SEO efforts, offline marketing, profit margins, sales process, and conversion rates, is crucial for crafting an effective plan. This approach ensures tailored strategies for success"
    },
    {
      icons: BsPcDisplayHorizontal,
      title: "Competitor Analysis",
      description: "To effectively surpass competitors in SEO, analyzing their strategies, strengths, weaknesses, and trends is crucial. Uncovering hidden assets informs our tailored approach to your SEO."
    },
    {
      icons: BsGear,
      title: "Growth Plan + Content",
      description: "After understanding your goals and priorities, we implement your monthly Growth Plan, optimizing existing content, adding new pages, and enhancing website structure for seamless usability, content, and navigation."
    },
    {
      icons: BsCart3,
      title: "Google Business Listings",
      description: "Every SEO strategy requires a Google Business listing. We'll review, optimize, and maintain your Google My Business listing to complement other monthly SEO efforts, ensuring comprehensive online visibility."
    },
    {
      icons: BsBook,
      title: "Measure, Report & Analyse",
      description: "Before implementing SEO updates, we ensure your Analytics, conversions, and tracking accurately measure inquiries and sales from SEO. This approach enables us to closely monitor the ROI of your SEO efforts."
    },
    {
      icons: BsFillPersonFill,
      title: "Review & Refine",
      description: "Based on your comprehensive SEO Growth Plan, we'll meet monthly or quarterly to regularly review progress, identify successes, address areas needing more attention, and adapt the strategy as needed."
    }
  ]
  return (
    <div>
      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}



      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-about-two-wrap mt-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className='tp-about-circle'>
                    <div className='tp-about-circle-two'>
                      <img src="assets/img/service/seo/01.jpg" alt="" loading='lazy' />
                    </div>
                  </div>
                  {/* <img src="assets/img/service/seo/01.jpg" alt="" loading='lazy' /> */}
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img src="assets/img/service/seo/02.jpg" alt="" loading='lazy' />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-two-wrapper mb-10 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">Elevate Your Online Visibility</span>
                  <h2 className="tp-section-title">SEARCH ENGINE OPTIMIZATION</h2>
                  <div className="tp-section-title-wrapper">
                    <p>At DGi WORX, we understand the pivotal role that search engine optimization (SEO) plays in the success of your online presence. SEO is the art and science of
                      optimizing your website to rank higher in search engine results, making it more visible to potential customers searching for products or services like yours.<br /><br />
                      From keyword research and on-page optimization to technical SEO and content marketing, we offer a comprehensive suite of SEO services tailored to your specific
                      industry and goals. Our data-driven approach ensures that every SEO strategy is backed by insights and analytics, allowing us to make informed decisions that
                      lead to tangible results.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Improved Online Visibility</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Organic Traffic Growth</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Keyword Optimization</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>On-Page and Technical SEO</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Content Marketing</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Data-Driven Insights</li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
          </div>
        </div>
      </section>




      {/* service section */}

      <section className="feature-area tp-feature-5-wrap py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Elevate Your Brand Online</span>
                <h2 className="tp-section-title">Digital Marketing Solutions</h2>
                <p>Discover how our tailored digital marketing solutions can elevate your brand's online presence, expand your reach, and boost your business's growth.</p>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="">
              <div className='sub-services mb-50'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/seo/seo_improve_business.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">HOW SEO IMPROVES YOUR BUSINESS</h4>
                          <p>In the digital age, having a strong online presence is a cornerstone of business success. SEO plays a pivotal role in achieving this goal by ensuring that
                            your website ranks prominently in search engine results when potential customers search for products or services you offer. It's not just about getting more
                            visitors; it's about attracting the right audience – people actively looking for what you provide.<br /><br />Our expert SEO strategies are designed to boost
                            your website's visibility, making it more accessible to your target audience. We focus on optimizing not only for search engines but also for user
                            experience. By doing so, we ensure that your website not only ranks well but also engages and converts visitors effectively.<br /><br />With our SEO services,
                            you can unlock the full potential of your online presence, increase your brand's credibility, and ultimately drive growth for your business. Let us help you
                            harness the power of SEO to take your business to new heights in the digital landscape.</p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub-services mb-50'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/seo/special.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">WHAT SO SPECIAL ABOUT DGI WORX</h4>
                          <p>At DGi WORX, our approach to digital marketing sets us apart. We're not just another SEO company; we're your strategic partner in achieving online success.
                            What makes us special is our unwavering commitment to delivering exceptional results and providing tailored solutions that cater to your unique business
                            needs.<br /><br />Our services go beyond just SEO. We offer a comprehensive suite of digital marketing solutions, including content marketing, social media
                            management, and paid advertising, to provide a well-rounded strategy for your online success.<br /><br />Your success is our primary focus. We're dedicated to
                            helping you achieve your business objectives, whether it's increasing visibility, driving more traffic, or boosting conversion rates.</p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub-services mb-50'>
                <div className='row'>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20" >

                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img src="assets/img/service/seo/ppc.jpg" loading='lazy' />
                        <div className="bottom-corner"></div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">PAY PER CLICK (PPC) CAMPAIGN</h4>
                          <p>Pay-Per-Click (PPC) advertising is a dynamic digital marketing strategy designed to bring immediate results. It allows you to display ads for your business
                            on search engines, social media platforms, or other websites and pay only when users click on your ads. At DGi WORX, we specialize in creating and managing
                            effective PPC campaigns that drive targeted traffic and boost your online visibility. <br /><br />Our PPC experts craft customized campaigns that align with
                            your specific business goals. We kick off by conducting extensive research to identify valuable opportunities for your business. This ensures that your PPC
                            campaign is precisely targeted to reach the right audience.<br /><br />Our team designs engaging ad copy and eye-catching graphics that resonate with your
                            brand and capture users' attention. We understand the importance of conveying your message effectively.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-10 mt-50">
                <span className="tp-section-sub-title">A Long-Term Strategy for Success</span>
                <h2 className="tp-section-title">Comprehensive SEO</h2>
                <p>SEO requires patience and dedication, but its long-term nature offers a strategic advantage over competitors. By committing to a 12-month digital roadmap, you can optimize your website to meet your business objectives effectively.</p>
              </div>
            </div>
          </div>


          <div className="py-50">
            <Boxcard item={seocard} />
          </div>
        </div>

      </section>




      <section className="seo-area tp-seo-5-wrap py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Unlocking Business Potential with SEO</span>
                <h2 className="tp-section-title">How Can Search Engine Optimization Help Your Business?</h2>
                <p>Search engine optimization plays a pivotal role in website design, offering benefits to both you and your audience. Let's explore the advantages of partnering with
                  us to develop a tailored page optimization strategy.</p>
              </div>
            </div>
          </div>
          <div>
            <div className='row'>
              <div className="col-lg-4 col-sm-12">
                <div className="tp-services-two-bg tp-thumb-common fix" data-background="assets/img/services/two/services-thumb-1.jpg">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <div className="tp-services-two-item">
                    <div className="tp-services-two-count">
                      <span>01</span>
                    </div>
                    <div className="tp-services-two-content">
                      <div className="tp-services-two-item-icon">
                        <i ><TbWorldSearch className="flaticon-data-visualization" /></i>
                      </div>
                      <h4 className="tp-services-two-title">
                        Boosted Traffic Levels
                      </h4>
                      <p>Leveraging a personalized search engine optimization strategy, grounded in authentic data, will propel your website up search engine rankings. Consequently,
                        your website will prominently feature when users search for keywords related to your niche. This heightened visibility translates to increased organic traffic,
                        ultimately driving more sales.</p>
                    </div>

                    <div className="tp-services-two-shape">
                      <img src="assets/img/services/two/services-2-shape-1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="tp-services-two-bg tp-thumb-common fix" data-background="assets/img/services/two/services-thumb-1.jpg">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <div className="tp-services-two-item">
                    <div className="tp-services-two-count">
                      <span>02</span>
                    </div>
                    <div className="tp-services-two-content">
                      <div className="tp-services-two-item-icon">
                        <i ><TbCurrencyDollar className="flaticon-data-visualization" /></i>
                      </div>
                      <h4 className="tp-services-two-title">
                        Enhanced Sales Conversion Rates
                      </h4>
                      <p>By prioritizing the refinement of your website, enhancing user experience, and making navigation seamless, your customers will find it effortless to explore
                        your offerings. Consequently, they will spend more quality time on your site compared to one with a subpar design, leading to significantly improved conversion
                        rates.</p>
                    </div>

                    <div className="tp-services-two-shape">
                      <img src="assets/img/services/two/services-2-shape-1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="tp-services-two-bg tp-thumb-common fix" data-background="assets/img/services/two/services-thumb-1.jpg">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <div className="tp-services-two-item">
                    <div className="tp-services-two-count">
                      <span>03</span>
                    </div>
                    <div className="tp-services-two-content">
                      <div className="tp-services-two-item-icon">
                        <i ><TbUserCode className="flaticon-data-visualization" /></i>
                      </div>
                      <h4 className="tp-services-two-title">
                        Enhanced User Experience
                      </h4>
                      <p>A reputable SEO services company doesn't solely concentrate on keywords and top-notch content; it also emphasizes website optimization and accessibility. By
                        giving due attention to these aspects, you can significantly enhance the user experience, encouraging visitors to spend more time on your site and increasing
                        the likelihood of them.</p>
                    </div>

                    <div className="tp-services-two-shape">
                      <img src="assets/img/services/two/services-2-shape-1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}


      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div>
  )
}

export default Seo;