import React from 'react';
import { BsPen, BsPerson, BsSend, BsPhone, BsTextarea, BsTelephone, BsGeoAlt, BsWhatsapp } from "react-icons/bs";
import { Helmet } from "react-helmet"
import Banner from '../../components/Bannersection/banner';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ContactCA() {
    const sendEmail = (event) => {
        event.preventDefault();
      
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_CA_SERVICE_ID, process.env.REACT_APP_EMAILJS_CA_TEMPLATE_ID, event.target, process.env.REACT_APP_EMAILJS_CA_USER_ID)
          .then((result) => {
            console.log(result.text);
            toast.success('Message sent successfully!', {
              position: toast.POSITION.TOP_RIGHT
            });
          }, (error) => {
            console.log(error.text);
            toast.error('Error sending message. Please try again later.', {
              position: toast.POSITION.TOP_RIGHT
            });
          });
      
        // Clear the form after sending the email
        event.target.reset();
      };
    const pagename = [
        {
            pagetitle: "Contact Us",
            subtitle: "Contact",
        }
    ]

    const company = {
        name: "DGi WORX",
        url: "https://dgiworx.ca/contact",
        logo: "https://dgiworx.ca/assets/img/logo/dgilogo/dgi.png",
        contactType: "customer support",
        telephone: "+61 0436291143",
        areaServed: "CA",
        sameAs: [
          "https://www.facebook.com/dgiworx",
          "https://www.instagram.com/dgiworx/",
          "https://www.behance.net/dgiworx",
          "https://www.linkedin.com/company/67087770/"
        ]
      };
    
    
      const schemaOrgJSONLD = {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "Organization",
            "name": company.name,
            "url": company.url,
            "logo": company.logo,
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": company.contactType,
              "telephone": company.telephone,
              "areaServed": company.areaServed
            },
            "sameAs": company.sameAs
          },
        
        ]
      };
    
    return (
        <div>
                  <Helmet>
                    <title>DGi WORX </title>
                    <meta
                    name="description"
                    content="Discover leading technology companies in canada driving innovation in tech, software, and digital solutions"
                    />
                    <meta
                    name="keywords"
                    content="software companies in canada , IT services canada,
                        technology companies in canada, Dgiworx canada, 
                        best software development company, "
                    />
                    <meta name="geo.country" content="canada" />
                    <meta name="geo.placename" content="Piara Waters, Western canada" />
                    <meta property="og:locale" content="en_au" />
                    <meta
                    property="og:title"
                    content="DGi WORX | Software Development Company"
                    />
                    <meta
                    property="og:description"
                    content="Discover leading technology companies in canada driving innovation in tech, software, and digital solutions"
                    />
                    <meta
                    property="og:image"
                    content="/assets/img/dgiworx-home-embedded.jpg"
                    />
                    <meta property="og:url" content="https://dgiworx.ca" />
                    <script type="application/ld+json">
                    {JSON.stringify(schemaOrgJSONLD)}
                    </script>
                </Helmet>
            {/* <!-- breadcrumb-area-start --> */}
            <Banner items={pagename} />

            {/* <!-- breadcrumb-area-end --> */}



            {/* <!-- contact-area-start --> */}
            <section className="contact-area py-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="tp-contact-main mb-40">
                                <div className="tp-section tp-section-two mb-25">
                                    <span className="tp-section-sub-title"><i ><BsTextarea className="flaticon-edit" /></i> Let’s Talk With Us</span>
                                    <h4 className="tp-section-title">Grow Your Business With Our Expertise</h4>
                                    <div className="tp-section-title-wrapper">
                                        <p>Get your project estimations for well-informed business decisions and top-notch digital outcomes.</p>
                                    </div>
                                </div>
                                <div className="tp-contact-main-thumb mb-40 w-img">
                                    <img src="assets/img/thumbs/contact-main-bg-1.jpg" alt="" />
                                </div>
                                <div className="tp-contact-location-wrap d-flex align-items-center">
                                    <div className="tp-contact-location">
                                        <span className="tp-contact-location-title">Canada</span>
                                        <p><i><BsGeoAlt className="fa-light fa-location-dot" /></i>7 Tom Edwards Dr,<br />  WhitbyOntario - L1R2R4 <br /> Canada.</p>
                                        
                                    </div>
                                    <div className="tp-contact-location ml-40">
                                        <a href="tel:+16477054453"><i><BsTelephone className="fa-light fa-phone" /></i> +1 647 705 4453</a>
                                        {/* <a href="https://wa.me/+18383838771" target="_blank"><i><BsWhatsapp className="fa-light fa-phone" /></i>+1 838 383 8771</a> */}
                                        <a href="mailto:projects@dgiworx.ca"><i><BsSend className="fa-light fa-envelope" /></i>projects@dgiworx.ca</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="offset-xl-1 col-xl-6 col-lg-6">
                            <div className="tp-contact-details-form mb-40">
                            <form id="contactform" className="row" action="" name="contactform" method="post" onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input type="text" name="first_name" id="fname" placeholder="First Name" required/>
                                                <span><i><BsPerson className="fa-light fa-user" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input type="text" placeholder="Last Name" name="last_name" id="lname" />
                                                <span><i><BsPerson className="fa-light fa-user" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input name="email" id="email" type="email" placeholder="Email Address" required/>
                                                <span><i><BsSend className="fa-light fa-envelope" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input type="text" placeholder="Phone Number" name="phone" id="phonenumber" required />
                                                <span><i><BsPhone className="fa-light fa-phone" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <textarea name="comments" id="message" placeholder="Write Your Message" required></textarea>
                                                <span><i><BsPen className="fa-light fa-pen" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-btn">
                                                <button className="tp-btn">Send a Message</button>
                                            </div>
                                        </div>
                                        <ToastContainer />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- contact-area-end --> */}


            {/* <!-- team-area-start --> */}
            {/* <section className="team-area py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tp-team-3-wrapper text-center">
                                <div className="tp-section tp-section-3 mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                    <span className="tp-section-sub-title">Meet With Our Specialist</span>
                                    <h2 className="tp-section-title">Our Core Team Members</h2>
                                    <p>When you want an exclusive website developed by the professionals, think of us, that is DGi WORX Team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/08.jpg" alt="" loading="lazy" />
                                    </div>
                                    
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Prabha</h5>
                                    <span>Managing Director</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/09.jpg" alt="" loading="lazy" />
                                    </div>
                                    
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Prabhu</h5>
                                    <span>VP - Technology</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/02.jpg" alt="" loading="lazy" />
                                    </div>
                                    
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Rajesh</h5>
                                    <span>Strategic Business Partner</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/03.jpg" alt="" loading="lazy" />
                                    </div>
                                    
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Reuben Raj</h5>
                                    <span>Head Of Programmer</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/05.jpg" alt="" loading="lazy" />
                                    </div>
                                    
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">S.A.K.Pandian</h5>
                                    <span>Web Artist</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/06.jpg" alt="" loading="lazy" />
                                    </div>
                                    
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Hari Hara Suthan</h5>
                                    <span>Software Craftsman</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- team-area-end --> */}

            {/* <div className="map-area">
                <div className="tp-map-wrapper">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d46263392.90799347!2d-6.8004560186699345!3d44.94990011898704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1686374976614!5m2!1sen!2sbd"></iframe>
                </div>
            </div> */}

            {/* <!-- map-area-end --> */}
        </div>
    )
}

export default ContactCA;